import { Recording } from '@/communications/recordings/models/recording-models';
import { AbstractRepository, ApiPagination, ApiParameters } from '@/repositories/abstract-repository';
import { InboxCountParams, InboxParams } from '@/communications/messages/models/inbox-params';
import { InboxCount } from '@/communications/messages/models/message';
import { getModule } from 'vuex-module-decorators';
import { PromiseStore } from '@/store/promise-store';

const promisesStore = getModule(PromiseStore);

/**
 * Handle requests for recordings made to/from families.
 */
export class RecordingsRepository extends AbstractRepository<Recording> {
    protected endpoint = 'communications/call-recordings';

    /**
     * Mark many recording as listened or not.
     * @param recordingIds
     * @param flag
     * @param isEnrollmentTeamMode
     */
    public async bulkMarkRead(recordingIds: Array<number>, flag = true, isEnrollmentTeamMode = false): Promise<void> {
        const endpoint = `${this.endpoint}/${flag ? 'mark-as-read' : 'mark-as-unread'}${
            isEnrollmentTeamMode ? '?enrollment_team=true' : ''
        }`;

        await this.client.put(endpoint, {
            ids: recordingIds
        });
    }

    public async getRecordingsForFamily(familyId: number, includeDismissed = false): Promise<Array<Recording>> {
        const response = await this.getAll({ family_id: familyId, include_dismissed: includeDismissed });
        return response.entities;
    }

    /**
     * Mark a recording as read.
     *
     * @param recording
     * @param isRead
     * @param isEnrollmentTeamMode
     */
    public async markAsRead(recording: Recording, isRead: boolean, isEnrollmentTeamMode = false): Promise<Recording> {
        // This is treated as a patch in the API even though it's a PUT
        if (isEnrollmentTeamMode) {
            return await this.client.put(this.endpoint + `/${recording.id}?enrollment_team=true`, {
                is_read: isRead
            });
        } else {
            return await this.putOne(recording.id, { is_read: isRead });
        }
    }

    /**
     * Get recordings for inbox
     *
     * @param orgId
     * @param isArchive
     * @param pagination?
     */
    public async getRecordingsForInbox(orgId: number, isArchive: boolean, pagination?: ApiPagination, force = true, isEnrollmentTeamMode = false): Promise<{ data: Array<Recording>; count: number }> {
        const params: ApiParameters = {
            org_id: orgId,
            inbox: true
        };
        if (isArchive) {
            params.include_dismissed = true;
            params.only_dismissed = true;
        }

        if (pagination) {
            params.limit = pagination.limit;
            params.offset = pagination.offset;
        }

        if (isEnrollmentTeamMode) {
            params.enrollment_team = true;
        }

        const response = await promisesStore.initPromise({
            hash: this.endpoint + '-' + JSON.stringify(params),
            closure: async () => {
                return this.client.get(this.endpoint, { params: params });
            },
            force: force
        });
        return {
            data: response.data,
            count: Number(response.headers['x-total-count'])
        };
    }

    /**
     * Get unlistened inbox count for recordings
     *
     * @param params
     * @param force?
     */
    public async getRecordingsCount(params: InboxCountParams, force = true): Promise<number> {
        const response = await promisesStore.initPromise({
            hash: this.endpoint + '/inbox-count' + '-' + JSON.stringify(params),
            closure: async () => {
                return this.client.get<InboxCount>(this.endpoint + '/inbox-count', { params: params });
            },
            force: force
        });

        return response.data.count;
    }

    /**
     * Bulk archive/unarchive recordings
     *
     * @param recordingIds
     * @param flag
     */
    public async toggleArchived(recordingIds: Array<number>, flag: boolean, isEnrollmentTeamMode = false): Promise<void> {
        if (isEnrollmentTeamMode) {
            await this.client.put(this.endpoint + '/' + (flag ? 'archive' : 'unarchive') + '?enrollment_team=true', {
                ids: recordingIds
            });
        } else {
            await this.client.put(this.endpoint + '/' + (flag ? 'archive' : 'unarchive'), {
                ids: recordingIds
            });
        }
    }

    // inbox search only (will only return incoming calls)
    public async search(searchString: string, isArchive: boolean, orgId = 1, isEnrollmentTeamMode = false) {
        const params: InboxParams = {
            org_id: orgId,
            inbox: true,
            search: searchString
        };

        if (isArchive) {
            params.include_dismissed = true;
            params.only_dismissed = true;
        }

        if (isEnrollmentTeamMode) {
            params.only_enrollment_team = true;
        }
        const results = await this.client.get(this.endpoint, { params: params });
        return results;
    }
}
