import { StaffPositionUpdateDto, User, UserWithAllPermissionsTableItem } from '@/staff/models/user';
import { UserAddOnSection } from '@/staff/models/user-settings-models';
import {
    BaseUserPermission, corpOnlyPermissions, franchiseCorpOnlyPermissions,
    Permission,
    PermissionName,
    UserPermission,
    UserPermissionViewDto
} from '@/staff/models/user-permission-models';
import { UserPermissionMapper } from '@/staff/mappers/user-permission-mapper';
import { getModule } from 'vuex-module-decorators';
import { UserPermissionsStore } from '@/staff/store/user-permissions-store';
import { UserPermissionsRepository } from '@/staff/repositories/user-permissions-repository';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { PermissionsStore } from '@/staff/store/permissions-store';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { SearchUtils } from '@/core/search-utils';
import { Center } from '@/organizations/locations/models/center';
import { EnrollmentCenterSettings, enrollmentTeamPlaceholder } from '@/enrollment-center/models/enrollment-center-models';

const authState = getModule(AuthStore, store);
const featureStore = getModule(FeaturesStore);
const permissionsStore = getModule(PermissionsStore);
const userPermissionMapper = new UserPermissionMapper();
const userPermissionsState = getModule(UserPermissionsStore);
const userPermissionsRepository = new UserPermissionsRepository();
const interfaceSettingsState = getModule(InterfaceSettingsStore);

export class StaffUtils {
    /**
     * Get the route section parameter from the add-on section's label.
     *
     * @param section
     */
    getAddOnRouteParamFromSection(section: UserAddOnSection): string {
        return section.label.replace(/\s+/g, '-').toLowerCase();
    }

    /**
     * Set permissions for existing users.
     *
     * @param userId
     * @param viewDtos
     */
    async updateUserPermissions(userId: number, viewDtos: Array<UserPermissionViewDto>) {
        const payload = [];
        for (const dto of viewDtos) {
            payload.push(userPermissionMapper.toUpdateDtoFromViewDto(dto));
        }
        await userPermissionsRepository.setPermissions(userId, payload);

    }

    /**
     * Set permissions for new users.
     *
     * @param userId
     * @param viewDtos
     */
    async updateNewUserPermissions(userId: number, viewDtos: Array<UserPermissionViewDto>) {
        const payload = [];
        let permission: UserPermission | null = null;
        for (const dto of viewDtos) {
            permission = await userPermissionsState.getPermissionByName({
                userId: userId,
                permissionName: dto.label
            }) as UserPermission;
            const createDto = userPermissionMapper.toCreateDto(permission as UserPermission);
            createDto.grants.update = dto.grants.update;
            payload.push(createDto);
        }
        await userPermissionsRepository.setPermissions(userId, payload);
    }

    async getUserPermission(permissionName: PermissionName): Promise<boolean> {
        const userInfo = authState.userInfoObject;
        if (!userInfo) {
            return false;
        }
        if (userInfo.is_superuser) {
            return true;
        }
        await Promise.all([featureStore.init(), interfaceSettingsState.init()]);
        if (!featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE)) {
            return true;
        }
        const permissionObject = await userPermissionsState.getPermissionByName({
            userId: userInfo.id,
            permissionName: permissionName
        });
        if (!permissionObject) {
            return false;
        }
        if (!interfaceSettingsState.enhancedPermissions && permissionObject.is_for_enhanced) {
            return true;
        }
        return permissionObject.grants.update;
    }

    async getAllUserPermissions(): Promise<Array<UserPermission> | null> {
        const userInfo = authState.userInfoObject;
        if (userInfo) {
            const permissionObject = await userPermissionsState.retrieveAllPermissions(userInfo.id);
            return permissionObject.entities;
        }
        return null;
    }

    /**
     * Given a userId, get a list of permissions the currently logged-in user can edit
     * @param userId
     */
    async getPermissionsAvailableToEdit(userId: number | null): Promise<Array<Permission>> {
        await permissionsStore.init();
        await interfaceSettingsState.init();
        const availablePermissions: Array<Permission> = [];

        // Filter out items that should not appear in permissions at all
        const permissions = permissionsStore.stored.filter(perm => perm.is_active && perm.label !== PermissionName.DashboardBothViews && (!perm.is_for_enhanced || interfaceSettingsState.enhancedPermissions));

        if (authState.isSuperuser) {
            return permissions;
        }

        await userPermissionsState.init(authState!.id as number, true);

        for (const permission of permissions) {
            if (permission.label === PermissionName.EnrollmentCenterEditLocation && !featureStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER)) {
                // Skip the Enrollment Center permission if feature not enabled
                continue;
            }

            if (featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE) && !authState.isCorporateUser) {
                if (userId && userId === authState.id) {
                    // Non-corporate users in CRM+ mode cannot modify their own permissions
                    continue;
                }
                const userPermission: UserPermission | undefined = userPermissionsState.entities.find(perm => perm.label === permission.label);
                // Non-corporate CRM+ users can only grant permissions they already have
                if (!userPermission || !userPermission.grants.update) {
                    continue;
                }

                const corpOnlyPermissions = [PermissionName.AutomationReminders, PermissionName.AutomationWorkflows];
                // Franchise mode allows non-corporate users access to more permissions
                if (!featureStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE)) {
                    corpOnlyPermissions.push(PermissionName.AutomationDripCampaign);
                }
                // Non-corporate users cannot see/set corporate permissions
                if (corpOnlyPermissions.includes(permission.label as PermissionName)) {
                    continue;
                }
            }

            availablePermissions.push(permission);
        }
        return availablePermissions;
    }

    /**
     * is a user an internal user?
     */
    isInternal(userInfo: User): boolean {
        return userInfo.is_superuser ||
            userInfo.email_address.match(/childcarecrm.com/i) !== null ||
            userInfo.email_address.match(/crmwebsolutions.com/i) !== null;
    }

    getPayloadForUpdatingEtRepLocations(originalIds: Array<number>, currentIds: Array<number>): Array<StaffPositionUpdateDto> {
        const combinedArray = [...new Set([...originalIds, ...currentIds])];
        const currentSetIds = new Set(currentIds);
        const payload: Array<StaffPositionUpdateDto> = [];
        combinedArray.forEach(id => {
            payload.push({ center: id, enrollment_rep: currentSetIds.has(id) });
        });
        return payload;
    }

    getEnhancedDefault(label: string, isCorpLevel: boolean) {
        if (isCorpLevel) {
            return true;
        }
        return label !== PermissionName.SettingsUsers && label !== PermissionName.AutomationViewMarketing;
    }

    /**
     * Given a particular permission and our permissions, return the permission it's controlled by
     *
     * @param name
     * @param permissions
     * @param enhancedMode
     */
    getGroupedPermissionControl(name: string, permissions: Array<Permission>, enhancedMode: boolean): string | null {
        if (!enhancedMode) {
            return null;
        }
        // special case, bleh
        if (name === PermissionName.SettingsAllGears) {
            return null;
        }
        const namedPermission = permissions.find(permission => permission.label === name);
        if (!namedPermission) {
            return null;
        }
        const groupId = namedPermission.group.id;
        const groupToggle = permissions.find(permission => permission.group.id === groupId && permission.is_group_toggle && permission.label !== name);
        if (!groupToggle) {
            return null;
        }
        return groupToggle.label;
    }

    handlePostClick(flag: boolean, permissionName: string, userPermissions: Array<BaseUserPermission>, permissions: Array<Permission>, enhancedMode: boolean) {
        if (flag) {
            // we don't do anything for clicking a control on
            return;
        }
        const permissionObject = permissions.find(permission => permission.label === permissionName);
        if (!permissionObject || !permissionObject.is_group_toggle) {
            return;
        }
        for (const userPermission of userPermissions) {
            if (this.getGroupedPermissionControl(userPermission.label, permissions, enhancedMode) === permissionName) {
                userPermission.grants.update = false;
            }
        }
    }

    isGroupLocked(permissionName: string, userPermissions: Array<BaseUserPermission>, permissions: Array<Permission>, enhancedMode: boolean) {
        if (!enhancedMode) {
            return false;
        }
        const groupToggleName = this.getGroupedPermissionControl(permissionName, permissions, enhancedMode);
        if (!groupToggleName) {
            return false;
        }
        const userControlPermission = userPermissions.find(userPermission => userPermission.label === groupToggleName);
        if (!userControlPermission) {
            // shouldn't really happen, but...
            return false;
        }
        return !userControlPermission.grants.update;
    }

    /**
     * Get the center director id for a center
     * @param center
     *
     * @returns center director id or null
     */
    getCenterDirectorId(center: Center | null): number | null {
        if (center && center.staff && center.staff.director) {
            return center.staff.director.id;
        }
        return null;
    }

    /**
     * Generates and sorts the staff members list for the center.
     *
     * @param staffMembers
     * @param onlyET
     * @param etOption
     * @param enrollmentCenterSettings
     * @param centerId
     * @returns Sorted list of staff members with initials and values.
     */
    getCenterStaffList(
        staffMembers: Array<User>,
        onlyET: boolean,
        etOption: boolean,
        enrollmentCenterSettings: EnrollmentCenterSettings | null,
        centerId: number | null = null
    ) {
        const locationItems = [];
        const otherItems = [];

        // Include ET option if enabled
        if (etOption && enrollmentCenterSettings) {
            otherItems.push({
                text: enrollmentCenterSettings.name,
                value: enrollmentTeamPlaceholder,
                initial: ''
            });
        }
        // Iterate over staff members and add valid entries
        for (const staff of staffMembers) {
            if (
                staff.is_service_account ||
                !staff.is_active ||
                (onlyET && !staff.is_on_enrollment_team)
            ) {
                continue;
            }
            if (centerId) {
                if (staff.center_id && staff.center_id === centerId) {
                    locationItems.push({
                        text: `${staff.first_name} ${staff.last_name}`,
                        value: staff.id,
                        initial: `${staff.first_name.substr(0, 1).toUpperCase()}${staff.last_name.substr(0, 1).toUpperCase()}`
                    });
                } else {
                    otherItems.push({
                        text: `${staff.first_name} ${staff.last_name}`,
                        value: staff.id,
                        initial: `${staff.first_name.substr(0, 1).toUpperCase()}${staff.last_name.substr(0, 1).toUpperCase()}`
                    });
                }
            }
        }

        // Sort items by the first word of the name (alphabetically)
        const sortByFirstWord = (a: any, b: any) => {
            const nameA = a.text.split(' ')[0].toLowerCase();
            const nameB = b.text.split(' ')[0].toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        };

        locationItems.sort(sortByFirstWord);
        otherItems.sort(sortByFirstWord);
        return (locationItems.length === 0 && otherItems.length === 0)
            ? []
            : [
                { header: 'Location Staff' },
                locationItems,
                { divider: true },
                { header: 'Others' },
                otherItems
            ].flat();
    }

    static searchUserPermissionsObject(search: string, user: UserWithAllPermissionsTableItem) {
        const tokens = search.split(/\s+/);
        return SearchUtils.searchStrings(tokens, [
            user.fullName,
            user.staff.values.username,
            user.phone,
            user.phone_formatted,
            (user.phone_formatted ?? '').replace(/\D/g, ''),
            user.email,
            user.orgLevel?.values.name ?? '',
            user.center?.values.short_name ?? '',
            user.center?.values.code ?? ''
        ]);
    }

    /**
     * Should a permission be shown, under various circumstances
     *
     * @param label
     * @param isFranchise
     * @param isCorp
     * @param isCenter
     */
    static isValidPerm(label: string, isFranchise: boolean, isCorp: boolean, isCenter: boolean) {
        const corpPermissions = isFranchise ? franchiseCorpOnlyPermissions : corpOnlyPermissions;
        if (!corpPermissions.includes(label)) {
            return true;
        }
        if (!isFranchise) {
            return isCorp;
        }
        return !isCenter;
    }

    /**
     * Copy permissions used in the User Permissions card
     *
     * @param source
     * @param target
     * @param isFranchise
     */
    static copyPerms(source: UserWithAllPermissionsTableItem, target: UserWithAllPermissionsTableItem, isFranchise: boolean) {
        const sourceIsCorp = !source.orgLevel || source.orgLevel.id === 1;
        const targetIsCorp = !target.orgLevel || target.orgLevel.id === 1;
        for (const perm of source.permissions) {
            for (const targetPerm of target.permissions) {
                if (targetPerm.label === perm.label) {
                    if (!StaffUtils.isValidPerm(perm.label, isFranchise, sourceIsCorp, source.isCenterUser) ||
                    !StaffUtils.isValidPerm(perm.label, isFranchise, targetIsCorp, target.isCenterUser)) {
                        continue;
                    }
                    targetPerm.grants.update = perm.grants.update;
                }
            }
        }
    }
}
