























































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Email, OutgoingEmailUpdateDto, OutgoingEmail, IncomingEmail } from '../models/email';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { MessageDirection, OutgoingStatusString } from '@/communications/messages/models/message';
import { AuthStore } from '@/store/auth-store';
import { EventTypes } from '@/constants/event-type-constants';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import { OutgoingEmailMapper } from '@/communications/mappers/outgoing-email-mapper';
import store from '@/store';
import EmailContentEditor from '@/communications/messages/components/EmailContentEditor.vue';
import {
    formatClockTime,
    formatDateForApi,
    formatDateWithTimezone,
    isAfterNow,
    isoFormat,
    timeFormat
} from '@/date-time/date-time-utils';
import { LoadingStore } from '@/store/loading-store';
import { getAvatarBackgroundFromUser } from '@/core/avatar-utils';
import { FamilyDocument } from '@/families/models/documents';
import { EmailAttachment } from '@/communications/templates/models/email-attachment';
import { EmailAttachmentsRepository } from '@/communications/templates/repositories/email-attachments-repository';
import { openFile } from '@/core/file-utils';
import { FileHateoasLink, HateoasLink } from '@/models/base';
import EmailAttachments from '@/communications/messages/components/EmailAttachments.vue';
import { FamilyDocumentsRepository } from '@/families/repositories/family-documents-repository';
import AddEditMessageModal from '@/communications/messages/components/AddEditMessageModal.vue';
import { Family } from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';

const appState = getModule(AppStateStore, store);
const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const emailsRepo = new EmailsRepository();
const docsRepo = new FamilyDocumentsRepository();
const emailAttachmentsRepo = new EmailAttachmentsRepository();
const outgoingEmailMapper = new OutgoingEmailMapper();
const familyRepository = new FamiliesRepository();

@Component({
    components: {
        AddEditMessageModal,
        EmailContentEditor,
        EmailAttachments
    }
})
export default class ViewSingleEmail extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly email!: Email;
    @Prop({ default: false }) readonly collapsed!: boolean;
    @Prop({ default: true }) readonly showTime!: boolean;

    private avatarInitials = '';
    private family: Family | null = null;
    private from = '';
    private to = '';
    private avatarBackgroundColor = '';
    private emailUpdateDto: OutgoingEmailUpdateDto | undefined;
    private emailUpdated = EventTypes.PENDING_EMAIL_UPDATED;
    private centerId = 0;
    private incomingEmailAttachments: Array<FamilyDocument> | null = null;
    private outgoingEmailAttachments: Array<EmailAttachment> = [];
    private showEditDialog = false;
    private loadingKey = 'emailEditDialog';
    // Properties used by editing the email.
    private messageContent = '';
    private sendDate = '';
    private sendFrom = 0;
    private sendTime = '';
    private subject = '';

    get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    /**
     * Is this an incoming message?
     */
    get isIncoming() {
        return this.email.type === MessageDirection.INCOMING;
    }

    /**
     * Is this message in pending status? Check the time that it should have been sent to be sure!
     */
    get isPending() {
        if (!this.isIncoming && (this.email as OutgoingEmail).status === OutgoingStatusString.PENDING) {
            if (!this.email.sent_date_time) {
                return true;
            }
            return isAfterNow(this.email.sent_date_time);
        }

        return false;
    }

    /**
     * Make sure we have changes to the message data.
     */
    get hasChanges() {
        if (this.isPending && this.emailUpdateDto) {
            const origDate = (this.email as OutgoingEmail).sent_date_time;
            const origSendDate = origDate ? formatDateForApi(origDate) : '';
            const origSendTime = origDate ? formatClockTime(origDate) : '';

            return this.messageContent !== '' &&
                (
                    this.email.html !== this.messageContent ||
                    this.email.subject !== this.subject ||
                    (this.email as OutgoingEmail).send_by_user?.id !== this.sendFrom ||
                    origSendDate !== this.sendDate ||
                    origSendTime !== this.sendTime
                );
        }

        return false;
    }

    @Watch('email', {
        deep: true,
        immediate: true
    })
    async emailChanged() {
        if (this.email.type === MessageDirection.INCOMING) {
            // Incoming
            this.from = this.email.from_email_address;
            this.avatarInitials = this.email.send_to_guardian!.values.first_name.substr(0, 1).toUpperCase() +
                this.email.send_to_guardian!.values.last_name.substr(0, 1).toUpperCase();
            this.avatarBackgroundColor = getAvatarBackgroundFromUser(this.email.send_to_guardian!.id);
            this.to = appState.storedCurrentCenter?.name ?? 'CRM';
        } else {
            if ((this.email as OutgoingEmail).send_by_user !== null) {
                this.from = (this.email as OutgoingEmail).send_by_user!.values.first_name +
                    ' ' +
                    (this.email as OutgoingEmail).send_by_user!.values.last_name;
                this.avatarInitials = (this.email as OutgoingEmail).send_by_user!.values.first_name.substr(0, 1).toUpperCase() +
                    (this.email as OutgoingEmail).send_by_user!.values.last_name.substr(0, 1).toUpperCase();
                this.avatarBackgroundColor = getAvatarBackgroundFromUser((this.email as OutgoingEmail).send_by_user!.id);
                this.sendFrom = (this.email as OutgoingEmail).send_by_user!.id;
            } else {
                this.from = appState.storedCurrentCenter?.name ?? 'ChildcareCRM';
                this.avatarInitials = 'CRM';
                this.avatarBackgroundColor = 'primary';
                this.sendFrom = 0;
            }
            this.emailUpdateDto = outgoingEmailMapper.toUpdateDto((this.email as OutgoingEmail));
            this.messageContent = this.email.html;
            this.subject = this.email.subject;
            const date = (this.email as OutgoingEmail).sent_date_time;
            if (date) {
                this.sendDate = formatDateWithTimezone(date, this.timezone, isoFormat);
                this.sendTime = formatDateWithTimezone(date, this.timezone, timeFormat);
            }
            this.to = this.email.send_to_guardian?.values?.first_name ?? '';
        }
    }

    async created() {
        this.centerId = appState.storedCurrentCenter ? appState.storedCurrentCenter.id : 0;

        if (this.isPending) {
            // Do this again to counter potential weirdness.
            this.emailUpdateDto = outgoingEmailMapper.toUpdateDto((this.email as OutgoingEmail));

            this.messageContent = this.email.html;
            this.subject = this.email.subject;
            if ((this.email as OutgoingEmail).send_by_user !== null) {
                this.sendFrom = (this.email as OutgoingEmail).send_by_user!.id;
            }

            const date = (this.email as OutgoingEmail).sent_date_time;
            if (date) {
                this.sendDate = formatDateWithTimezone(date, this.timezone, isoFormat);
                this.sendTime = formatDateWithTimezone(date, this.timezone, timeFormat);
            }

            if (this.email.send_to_guardian?.values.family_id) {
                this.family = await familyRepository.getOne(this.email.send_to_guardian.values.family_id);
            }
        }
    }

    private async cancelPendingEmail(id: number) {
        await this.$swal({
            text: 'Are you sure you want to cancel sending this email message?',
            showConfirmButton: true,
            showCancelButton: true
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                await emailsRepo.cancelPendingEmail(id);
                this.$emit(EventTypes.PENDING_EMAIL_CANCELLED);
            }
        });
    }

    private async openEditDialog() {
        if (this.isPending) {
            this.showEditDialog = true;
        }
    }

    private startReply() {
        this.$emit(EventTypes.START_REPLY, this.email);
    }

    private async updateEmail() {
        this.$emit(EventTypes.PENDING_EMAIL_UPDATED);
    }

    private async open(fileLink: FileHateoasLink | HateoasLink) {
        loadingState.loadingIncrement(this.loadingKey);
        let fileContent;
        let fileMetaData: FamilyDocument | EmailAttachment;
        let mimeType;
        if (this.isIncoming) {
            const familyId = (this.email as IncomingEmail).send_to_guardian?.values.family_id;
            fileContent = await docsRepo.retrieveFileById(familyId!, fileLink.id);
            fileMetaData = await docsRepo.retrieveFileMetaDataById(familyId!, fileLink.id);
            mimeType = fileMetaData.file_type;
        } else {
            fileContent = await emailAttachmentsRepo.getFileById(fileLink.id);
            fileMetaData = await emailAttachmentsRepo.getFileMetaDataById(fileLink.id);
            mimeType = fileMetaData.mime_type;
        }
        openFile(fileContent, fileMetaData.filename, mimeType);
        loadingState.loadingDecrement(this.loadingKey);
    }

}
