















































































































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import TaskGroupsService, { GroupedType, ResultGroupedType } from '@/tasks/services/task-groups-service';
import { SortableEvent } from 'sortablejs';
import BaseClose from '@/components/base/BaseClose.vue';
import { TaskGroupTableItem } from '@/tasks/models/task-models';

const loadingStore = getModule(LoadingStore);
const taskGroupsService = new TaskGroupsService();
@Component({
    components: { BaseClose }
})
export default class TaskSettings extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly embedded!: boolean;
    @Prop({ default: false }) readonly saveFlag!: boolean;
    private loadingKey = 'task-settings';
    private isLoaded = false;
    private resultsHeaders = ['Result Name', 'Task Group'];
    private taskHeaders = ['Task Name', 'Task Group'];
    private taskGroupItems: Array<{ text: string; value: number }> = [];
    private taskResultGroups: Array<{ text: string; value: number; disabled?: boolean }> = [];
    private resultsKey = 0;
    private tasksKey = 0;
    private groupsKey = 0;
    private onlyLogsId = TaskGroupsService.onlyLogId;
    private headers = [
        {
            text: '',
            value: 'handle'
        },
        {
            text: 'Name',
            value: 'value',
            align: 'center',
            width: '60%'
        },
        {
            text: 'Task Group',
            value: 'group',
            width: '30%'
        }
    ];

    private resultHeaders = [
        {
            text: '',
            value: 'handle'
        },
        {
            text: 'Name',
            value: 'value',
            align: 'center',
            width: '60%'
        },
        {
            text: 'Task Group',
            value: 'group',
            width: '35%'
        },
        {
            value: 'log',
            text: 'Log',
            width: '5%'
        }
    ];

    private groupsHeaders = [
        {
            text: 'Task Group Name',
            value: 'name',
            width: '25ch'
        },
        {
            text: '',
            value: 'action'
        }
    ];

    get taskItems(): Array<GroupedType> {
        return taskGroupsService.taskGroupedTypes;
        // return taskGroupsService.taskGroupedTypes?.filter((option: CrmTypeOption) => (option.is_active && !option.is_hidden)) ?? [];
    }

    get resultsItems(): Array<GroupedType> {
        return taskGroupsService.resultGroupedTypes;
    }

    get groupDisplayItems(): Array<TaskGroupTableItem> {
        return taskGroupsService.taskGroupTableDisplayItems;
    }

    @Watch('saveFlag')
    async saveTriggered() {
        if (this.saveFlag) {
            await this.save();
        }
    }

    async mounted() {
        loadingStore.loadingIncrement(this.loadingKey);
        await taskGroupsService.init();
        for (const group of taskGroupsService.taskGroups) {
            this.taskGroupItems.push({ text: group.name, value: group.id });
        }
        this.taskResultGroups = [
            ...this.taskGroupItems
        ];
        this.taskResultGroups.push({
            value: TaskGroupsService.onlyLogId,
            text: 'Only Logs'
        });
        this.isLoaded = true;
        loadingStore.loadingDecrement(this.loadingKey);
    }

    addResultType() {
        taskGroupsService.addResultType();
        ++this.resultsKey;
    }

    addTaskGroup() {
        taskGroupsService.addTaskGroup();
        ++this.groupsKey;
    }

    addTaskType() {
        taskGroupsService.addTaskType();
        ++this.tasksKey;
    }

    async cancel() {
        this.$emit(EventTypes.CLOSE);
    }

    checkOnlyLogs(item: ResultGroupedType) {
        if (!!item.groupId && item.groupId === this.onlyLogsId) {
            item.isLog = true;
        }
    }

    deleteResult(item: GroupedType) {
        taskGroupsService.removeResult(item);
        ++this.resultsKey;
    }

    async deleteTaskGroup(item: TaskGroupTableItem) {
        if (item.id > 0) {
            const result = await this.$swal({
                text: 'Warning: If this task group is deleted, any tasks or results that are assigned to it will be reassigned to the “Other” task group. Do you still want to delete this task group?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            });
            if (result.isConfirmed) {
                taskGroupsService.removeTaskGroup(item);
                ++this.groupsKey;
            }

        } else {
            taskGroupsService.removeTaskGroup(item);
            ++this.groupsKey;
        }

    }

    deleteTask(item: GroupedType) {
        taskGroupsService.removeTask(item);
        ++this.tasksKey;
    }

    async save() {
        if (!this.embedded) {
            document.getElementById('to-do-settings-top')!.scrollIntoView();
        }
        loadingStore.loadingIncrement(this.loadingKey);
        await taskGroupsService.save();
        loadingStore.loadingDecrement(this.loadingKey);
        this.$emit(EventTypes.UPDATED, 'Task Settings Updated');
    }

    resultsSorted(sortEvent: SortableEvent) {
        if (sortEvent.oldIndex === undefined || sortEvent.newIndex === undefined) {
            return;
        }
        taskGroupsService.updateResultsOrder(sortEvent.oldIndex, sortEvent.newIndex);
    }

    tasksSorted(sortEvent: SortableEvent) {
        if (sortEvent.oldIndex === undefined || sortEvent.newIndex === undefined) {
            return;
        }
        taskGroupsService.updateTasksOrder(sortEvent.oldIndex, sortEvent.newIndex);
    }
}
