









































import TourCalendar from '@/components/tasks/TourCalendar.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { AddMeetingEventPayload, EditMeetingEventPayload } from '@/tasks/models/tour-calendar-models';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import BaseClose from '@/components/base/BaseClose.vue';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';

const appState = getModule(AppStateStore);

@Component({
    components: {
        BaseClose,
        TourCalendar
    }
})
export default class TourCalendarModal extends Mixins(LocaleMixin) {
    /** v-model whether we should show it. */
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ default: null }) readonly familyCenterId!: number | undefined;

    private addEvent = EventTypes.MEETING_ADD;
    private editEvent = EventTypes.MEETING_EDIT;
    private showPstAvailableHours = false;

    created() {
        this.showPstAvailableHours = appState.displayPstAvailableHours;
    }

    /**
     * Handles when to show the modal.
     */
    private get modelValue(): boolean {
        return this.value;
    }

    /**
     * Handles showing the modal.
     */
    private set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    /**
     * Close the modal.
     */
    private close() {
        this.modelValue = false;
    }

    /**
     * Handle trying to add a meeting from the calendar action.
     *
     * @param payload
     */
    private handleAddMeeting(payload: AddMeetingEventPayload) {
        this.$emit(this.addEvent, payload);
        this.close();
    }

    /**
     * Handle trying to edit a meeting from the calendar action.
     *
     * @param payload
     */
    private handleEditMeeting(payload: EditMeetingEventPayload) {
        this.$emit(this.editEvent, payload);
        this.close();
    }

    @Watch('showPstAvailableHours')
    updateShowPstCheckBox() {
        appState.setShowPstAvailableHours(this.showPstAvailableHours);
    }

}
