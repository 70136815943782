import DOMPurify from 'dompurify';
import { Email, OutgoingEmail } from '@/communications/messages/models/email';
import { MessageDirection } from '@/communications/messages/models/message';
import { LocaleMixin } from '@/locales/locale-mixin';

export function getReplySubject(subject: string): string {
    if (subject.match(/^re:/i)) {
        return subject;
    }
    return 'Re: ' + subject;
}

export function getReplyBody(body: string, from: string): string {
    const clean = DOMPurify.sanitize(body);
    let result = '<br><br>';
    if (from) {
        result = result + '<div>' + from + ' wrote: </div>';
    }
    // gmail style quoting
    result = result + '<blockquote style="margin:0 0 0 0.8ex; border-left: 1px solid rgb(204,204,204); padding-left:1ex">' + clean + '</blockquote>';
    return result;
}

export function emailIsValid(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function getDeliveryStatusMessage(email: Email, emailTimeZone: string, locale: string) {
    let deliveryStatusMessage = '';
    const localeMixin = new LocaleMixin();

    let eventStatus = '';
    if (email.type === MessageDirection.INCOMING) {
        eventStatus = 'incoming';
    } else {
        if (email.delivery_status) {
            eventStatus = email.delivery_status;
        } else {
            eventStatus = email.status;
        }
    }

    let sentDate = '';
    let sentTime = '';
    if (email?.sent_date_time) {
        sentDate = localeMixin.formatDateWithoutLeadingZero(email.sent_date_time, emailTimeZone, locale);
        sentTime = localeMixin.formatTimeWithTimezoneWithoutLeadingZero(email.sent_date_time, emailTimeZone, locale);
    }

    let messageDate = '';
    let messageTime = '';
    if (email?.event_date_time) {
        messageDate = localeMixin.formatDateWithoutLeadingZero(email.event_date_time, emailTimeZone, locale);
        messageTime = localeMixin.formatTimeWithTimezoneWithoutLeadingZero(email.event_date_time, emailTimeZone, locale);
    }

    let cancelDate = '';
    let cancelTime = '';
    if (email?.cancel_date_time) {
        cancelDate = localeMixin.formatDateWithoutLeadingZero(email.cancel_date_time, emailTimeZone, locale);
        cancelTime = localeMixin.formatTimeWithTimezoneWithoutLeadingZero(email.cancel_date_time, emailTimeZone, locale);
    }

    const guardian = (email?.send_to_guardian?.values?.first_name ? email.send_to_guardian.values.first_name : 'Unknown');

    switch (eventStatus) {
        case 'Pending':
            deliveryStatusMessage = 'This email is pending delivery on ' + sentDate + ' at roughly ' + sentTime + '.';
            break;
        case 'processed':
            deliveryStatusMessage = 'This email is pending delivery on ' + messageDate + ' at roughly ' + messageTime + '.';
            break;
        case 'Sent':
            deliveryStatusMessage = 'This email was sent on ' + sentDate + ' at ' + sentTime + ' but is still being processed by the recipient’s email provider.';
            break;
        case 'delivered':
            deliveryStatusMessage = 'This email was delivered on ' + messageDate + ' at ' + messageTime + ' but may not have been opened by ' + guardian + ' yet.';
            break;
        case 'open':
            deliveryStatusMessage = 'This email was last opened on ' + messageDate + ' at ' + messageTime + '.';
            break;
        case 'click':
            deliveryStatusMessage = 'This email was last opened on ' + messageDate + ' at ' + messageTime + '.';
            break;
        case 'Cancelled':
            if (email.cancelled_by !== '' && email.cancel_date_time) {
                deliveryStatusMessage = 'This email was Canceled on ' + cancelDate + ' at ' + cancelTime + ' by ' + email.cancelled_by + '.';
            } else if (email.cancelled_by === '' && email.cancel_date_time) {
                deliveryStatusMessage = 'This email was Canceled on ' + cancelDate + ' at ' + cancelTime + '.';
            } else if (email.cancelled_by !== '' && email.cancel_date_time === null) {
                deliveryStatusMessage = 'This email was Canceled by ' + email.cancelled_by + '.';
            } else {
                deliveryStatusMessage = 'This email was Canceled.';
            }
            break;
        case 'Failed':
        case 'dropped':
            deliveryStatusMessage = 'This email failed to deliver.';
            break;
        case 'deferred':
            deliveryStatusMessage = 'This email was sent on ' + messageDate + ' at ' + messageTime + ' but has not yet reached ' + guardian + '\'s inbox.';
            break;
        case 'bounce':
            deliveryStatusMessage = 'This email bounced and was never received by ' + guardian + '.';
            break;
        case 'spamreport':
            deliveryStatusMessage = 'This email was delivered on ' + messageDate + ' at ' + messageTime + ' but ' + guardian + ' marked it as spam.';
            break;
        case 'unsubscribed':
            deliveryStatusMessage = 'This email was delivered on ' + messageDate + ' at ' + messageTime + ', but ' + guardian + ' clicked the “Unsubscribe” link.';
            break;
        case 'Suspended':
            deliveryStatusMessage = 'This email is part of a suspended drip campaign and will be sent when the drip campaign is unsuspended.';
            break;
        default:
            deliveryStatusMessage = '';
    }
    return deliveryStatusMessage;
}

/**
 * Get the email header "To" field
 *
 * This function returns the recipient's name and email address in the format "Name (email@example.com)".
 * If the recipient's name or email address is not available, it returns an empty string.
 *
 * @param email - The outgoing email object
 * @returns A string containing the recipient's name and email address, or an empty string if not available
 */
export function getEmailHeaderTo(email: OutgoingEmail): string {
    const guardianName = email.send_to_guardian?.values?.name;
    const emailAddress = email.sent_to_email_address;
    return guardianName && emailAddress ? `${guardianName} (${emailAddress})` : '';
}

/**
 * Get the email header "From" field
 *
 * @param email - The outgoing email object
 * @returns A string containing the sender's full name, or an empty string if not available
 */
export function getEmailHeaderFrom(email: OutgoingEmail): string {
    const user = email.send_by_user?.values;
    return user ? `${user.first_name} ${user.last_name}` : '';
}

/**
 * Get the email header "Group Email" field
 *
 * @param email - The outgoing email object
 * @returns A string containing the group email or "No" if not available
 */
export function getEmailHeaderGroupEmail(email: OutgoingEmail): string {
    return email.group_email || 'No';
}

/**
 * Get the email header "CC" field
 *
 * @param email - The outgoing email object
 * @returns A string containing the cc'ed user's full name, or an empty string if not available
 */
export function getEmailHeaderCC(email: OutgoingEmail): string {
    const user = email.cc_user?.values;
    return user ? `${user.first_name} ${user.last_name}` : '';
}

/**
 * Get the email header "BCC" field
 *
 * @param email - The outgoing email object
 * @returns A string containing the bcc'ed user's full name, or an empty string if not available
 */
export function getEmailHeaderBCC(email: OutgoingEmail): string {
    const user = email.bcc_user?.values;
    return user ? `${user.first_name} ${user.last_name}` : '';
}

/**
 * Get the email header "Attachments" field
 *
 * @param email - The outgoing email object
 * @returns An array of strings, each containing the name of an attachment, or an empty array if no attachments are available
 */
export function getEmailHeaderAttachments(email: OutgoingEmail): Array<string> {
    if (email.attachments) {
        return email.attachments.map(attachment => attachment.values.name ?? '');
    }
    return [];
}
