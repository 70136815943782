







































































































































































































































































































































































































import { EventTypes } from '@/constants/event-type-constants';
import {
    differenceInDaysLocal,
    formatDateWithTimezone,
    formatIsoDateTime, getTimeZoneString,
    isoFormatLong
} from '@/date-time/date-time-utils';
import { ChangeStatus } from '@/families/change-status';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import { Family } from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import DurationSelect from '@/tasks/components/DurationSelect.vue';
import GuardiansCard from '@/tasks/components/GuardiansCard.vue';
import TaskDescription from '@/tasks/components/TaskDescription.vue';
import TaskDueDateTime from '@/tasks/components/TaskDueDateTime.vue';
import TaskResultSelect from '@/tasks/components/TaskResultSelect.vue';
import TaskTypeSelect from '@/tasks/components/TaskTypeSelect.vue';
import { TaskMapper } from '@/tasks/mappers/task-mapper';
import { Task, TaskType, TaskUpdateDto } from '@/tasks/models/task-models';
import { LocaleMixin } from '@/locales/locale-mixin';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import { isTaskPastDue, TaskUtilities } from '@/tasks/task-utils';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import { AppStateStore } from '@/store/app-state-store';
import SendReminderInputs from '@/tasks/components/SendReminderInputs.vue';
import { isDoNotEmail, isDoNotText } from '@/families/families-utils';
import { StatusChangesStore } from '@/families/store/status-changes-store';
import { enrollmentTeamPlaceholder } from '@/enrollment-center/models/enrollment-center-models';
import { TasksStore } from '@/tasks/stores/tasks-store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { downloadIcsFile } from '@/calendar/calendar-utils';
import { SixAgentStore } from '@/integrations/store/six-agent-store';
import { SixAgentUtils } from '@/integrations/six-agent-utils';
import BaseClose from '@/components/base/BaseClose.vue';
import { LayoutTabsStore } from '@/store/layout-tabs-store';

const appState = getModule(AppStateStore);
const authStore = getModule(AuthStore, store);
const changeStatus = new ChangeStatus();
const changeStatusStore = getModule(StatusChangesStore);
const familiesRepository = new FamiliesRepository();
const loadingState = getModule(LoadingStore);
const staffUtils = new StaffUtils();
const taskMapper = new TaskMapper();
const tasksRepository = new TasksRepository();
const taskUtils = new TaskUtilities();
const centersStore = getModule(CentersStore);
const tasksStore = getModule(TasksStore);
const featuresStore = getModule(FeaturesStore);
const sixAgentState = getModule(SixAgentStore);
const sixAgentUtils = new SixAgentUtils();
const layoutTabsStore = getModule(LayoutTabsStore, store);

@Component({
    components: {
        BaseClose,
        SendReminderInputs,
        GuardiansCard,
        DurationSelect,
        TaskTypeSelect,
        TaskDueDateTime,
        TaskResultSelect,
        TaskDescription,
        StatusChangeSelect,
        CenterAscendingStaffList,
        AddTaskModal
    }
})
export default class ManageTaskModal extends Mixins(LocaleMixin) {
    @Prop() task!: Task | null;
    @Prop() isMeeting!: boolean;
    @Prop({ default: false }) completedEdit!: boolean;
    @Prop({ default: false }) readonly etDashMode!: boolean;

    private taskDto: TaskUpdateDto | null = null;
    private family: Family | null = null;
    private assignedToStaffId!: number;
    private taskSaved = false;
    private taskSavedMessage = `${this.isMeeting ? 'Meeting' : 'Task'} Updated.`;
    private resultDescription = '';
    private saveButtonText = 'Save';
    private isLoaded = false;
    private isOpen = true;
    private isComplete = false;
    private isChanged = false;
    private isDueDateChange = false;
    private isTaskTypeChange = false;
    private isDescriptionChange = false;
    private isValid = true;
    private isValidDueDate = true;
    private loadingKey = 'manageTaskLoading';
    // Task type name before changes
    private taskTypeName = this.task?.type.values.value;
    // Event for viewing a family
    private viewFamilyEvent = EventTypes.FAMILY_VIEW;
    private tzDue = '';
    private newTzDue: Date | null = null;
    private addTaskModal = false;
    private currentTab = '';
    private isResultChanged = false;
    private canEdit = false;
    private canEmail = true; // Is "email" reminder allowed?
    private canText = true; // Is "text" reminder allowed?
    private exportToIcsFile = false;
    private sendTextReminderGuardian = true;
    private sendEmailReminderGuardian = true;
    private sendTextReminderStaff = true;
    private sendEmailReminderStaff = true;
    private guardianTextReminderAmount: string | null = null;
    private guardianTextReminderUnit: string | null | undefined = null;
    private guardianEmailReminderAmount: string | null = null;
    private guardianEmailReminderUnit: string | null | undefined = null;
    private staffTextReminderAmount: string | null = null;
    private staffTextReminderUnit: string | null | undefined = null;
    private staffEmailReminderAmount: string | null = null;
    private staffEmailReminderUnit: string | null | undefined = null;
    private etPlaceholder = enrollmentTeamPlaceholder;
    private initialPastDue = false;
    // we have to keep track of tz edit separately so it can be changed
    // independently of time
    private chosenTimezone = '';
    private description = '';

    get center() {
        return appState.storedCurrentCenter;
    }

    get isCancelled(): boolean {
       if (this.task) {
           return this.task.is_cancelled;
       }
        return false;
    }

    get taskLabel() {
        return this.isMeeting ? 'meeting' : 'task';
    }

    get showTimezones() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get dueTimeString() {
        if (this.taskDto) {
            return this.formatTime(this.taskDto.due_date_time, this.timezone) + (this.showTimezones ? ' ' + this.timezoneString : '');
        }
        return '';
    }

    get timezoneString() {
        if (this.taskDto && this.chosenTimezone) {
            return getTimeZoneString(new Date(this.taskDto.due_date_time), this.chosenTimezone, this.$i18n.locale);
        }
        return '';
    }

    get sixAgentContactCount() {
        return sixAgentState.contactCount;
    }

    get canAddCrmTabs(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.INCONTACT) && authStore.isEnrollmentTeamMember;
    }

    get newTabStatus() {
        return this.etDashMode && appState.familyHubTargetMode;
    }

    get timezone() {
        return authStore.userTimeZone;
    }

    async getTzDue() {
        if (!this.taskDto) {
            return '';
        }

        return formatDateWithTimezone(this.taskDto.due_date_time, this.timezone, isoFormatLong);
    }

    @Watch('sixAgentContactCount')
    sixAgentCountChanged(newCount: number, oldCount: number) {
        if (newCount > oldCount && sixAgentState.loggedIn && this.family) {
            sixAgentUtils.addPossibleContact(this.family);
        }
    }

    @Watch('resultDescription')
    private updateResultDescription() {
        if (!this.taskDto) {
            return;
        }
        this.isChanged = this.taskDto.result_description !== this.resultDescription;
    }

    @Watch('description')
    private updateDescription() {
        if (!this.taskDto) {
            return;
        }
        this.isChanged = this.taskDto.description !== this.description;
    }

    @Watch('task', { immediate: true, deep: true })
    async updateData() {
        if (!this.task) {
            // Reset state.
            this.isOpen = false;
            this.isComplete = false;
            this.isChanged = false;
            this.isDueDateChange = false;
            this.isTaskTypeChange = false;
            this.isValid = true;
            this.isResultChanged = false;
            this.isValidDueDate = true;
            this.exportToIcsFile = false;
            this.taskDto = null;
            if (this.family) {
                changeStatusStore.clear(this.family.id);
            }
            this.isLoaded = false;
            this.tzDue = '';

            this.canEdit = false;
            this.canEmail = true;
            this.sendEmailReminderGuardian = this.canEmail;
            this.canText = true;
            this.sendTextReminderGuardian = this.canText;
            return;
        }

        if (this.task) {
            loadingState.loadingIncrement(this.loadingKey);
            const responses = await Promise.all([
                familiesRepository.getOne(this.task.family.id),
                staffUtils.getUserPermission(PermissionName.TasksEditOthers)
            ]);
            const canEditOthersTasks = responses[1];
            this.chosenTimezone = this.timezone;
            this.family = responses[0];
            if (sixAgentState.loggedIn) {
                sixAgentUtils.addPossibleContact(this.family);
            }
            this.assignedToStaffId = this.task.assigned_to_staff ? this.task.assigned_to_staff.id : 0;
            if (this.task.assigned_to_enrollment_team === 1 && this.assignedToStaffId === 0) {
                this.assignedToStaffId = enrollmentTeamPlaceholder;
            }
            this.canEdit = !(this.assignedToStaffId !== authStore.userInfoObject?.id && !canEditOthersTasks);
            this.exportToIcsFile = false;
            this.taskDto = taskMapper.toUpdateDto(this.task);
            this.taskTypeName = this.task.type.values.value;
            this.tzDue = await this.getTzDue();
            this.newTzDue = null;
            this.canEmail = !isDoNotEmail(this.family);
            this.sendEmailReminderGuardian = this.canEmail && this.task.reminder_times.primary_guardian_email_reminder !== null && parseInt(this.task.reminder_times.primary_guardian_email_reminder.split('')[0]) !== 0;
            this.canText = !isDoNotText(this.family);
            this.sendTextReminderGuardian = this.canText && this.task.reminder_times.primary_guardian_text_reminder !== null && parseInt(this.task.reminder_times.primary_guardian_text_reminder.split('')[0]) !== 0;
            this.sendEmailReminderStaff = this.task.reminder_times.staff_email_reminder !== null && parseInt(this.task.reminder_times.staff_email_reminder.split('')[0]) !== 0;
            this.sendTextReminderStaff = this.task.reminder_times.staff_text_reminder !== null && parseInt(this.task.reminder_times.staff_text_reminder.split('')[0]) !== 0;

            if (this.isMeeting) {
                if (this.sendEmailReminderGuardian) {
                    this.taskDto.primary_guardian_email_reminder_amount = this.task.reminder_times.primary_guardian_email_reminder ? parseInt(this.task.reminder_times.primary_guardian_email_reminder.split(' ')[0]) : null;
                    this.taskDto.primary_guardian_email_reminder_unit = this.task.reminder_times.primary_guardian_email_reminder ? this.task.reminder_times.primary_guardian_email_reminder.split(' ')[1] : null;
                }
                if (this.sendTextReminderGuardian) {
                    this.taskDto.primary_guardian_text_reminder_amount = this.task.reminder_times.primary_guardian_text_reminder ? parseInt(this.task.reminder_times.primary_guardian_text_reminder.split(' ')[0]) : null;
                    this.taskDto.primary_guardian_text_reminder_unit = this.task.reminder_times.primary_guardian_text_reminder ? this.task.reminder_times.primary_guardian_text_reminder.split(' ')[1] : null;
                }
                this.initialPastDue = isTaskPastDue(this.taskDto);
            }

            if (this.sendEmailReminderStaff) {
                this.taskDto.staff_email_reminder_amount = this.task.reminder_times.staff_email_reminder ? parseInt(this.task.reminder_times.staff_email_reminder.split(' ')[0]) : null;
                this.taskDto.staff_email_reminder_unit = this.task.reminder_times.staff_email_reminder ? this.task.reminder_times.staff_email_reminder.split(' ')[1] : null;
            }

            if (this.sendTextReminderStaff) {
                this.taskDto.staff_text_reminder_amount = this.task.reminder_times.staff_text_reminder ? parseInt(this.task.reminder_times.staff_text_reminder.split(' ')[0]) : null;
                this.taskDto.staff_text_reminder_unit = this.task.reminder_times.staff_text_reminder ? this.task.reminder_times.staff_text_reminder.split(' ')[1] : null;
            }

            this.guardianTextReminderAmount = this.taskDto.primary_guardian_text_reminder_amount ? this.taskDto.primary_guardian_text_reminder_amount.toString() : null;
            this.guardianTextReminderUnit = this.taskDto.primary_guardian_text_reminder_unit ? this.taskDto.primary_guardian_text_reminder_unit : null;
            this.guardianEmailReminderAmount = this.taskDto.primary_guardian_email_reminder_amount ? this.taskDto.primary_guardian_email_reminder_amount.toString() : null;
            this.guardianEmailReminderUnit = this.taskDto.primary_guardian_email_reminder_unit ? this.taskDto.primary_guardian_email_reminder_unit : null;
            this.staffTextReminderAmount = this.taskDto.staff_text_reminder_amount ? this.taskDto.staff_text_reminder_amount.toString() : '15';
            this.staffTextReminderUnit = this.taskDto.staff_text_reminder_unit ? this.taskDto.staff_text_reminder_unit : 'minute';
            this.staffEmailReminderAmount = this.taskDto.staff_email_reminder_amount ? this.taskDto.staff_email_reminder_amount.toString() : '10';
            this.staffEmailReminderUnit = this.taskDto.staff_email_reminder_unit ? this.taskDto.staff_email_reminder_unit : 'hour';
            this.resultDescription = this.taskDto.result_description ? this.taskDto.result_description : '';
            this.description = this.taskDto.description ? this.taskDto.description : '';
            this.isLoaded = true;
            this.isOpen = true;
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('exportToIcsFile')
    private triggerChange() {
        if (this.exportToIcsFile) {
            this.isChanged = true;
        }
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);

        // Make sure centers are loaded up.

        const centersResponse = centersStore.initAccessibleCenters();
        const featureResponse = featuresStore.init();
        await centersResponse;
        await featureResponse;

        loadingState.loadingDecrement(this.loadingKey);
    }

    async mounted() {
        if (this.task) {
            await this.updateData();
        }
    }

    private dueColor() {
        if (!this.taskDto) {
            return;
        }

        if (!this.task) {
            return '';
        }

        const today = new Date();
        if (differenceInDaysLocal(this.taskDto.due_date_time, today.toDateString()) > 0) {
            return 'red--text';
        }
        return '';
    }

    private setTaskType(taskType: TaskType) {
        if (!this.taskDto) {
            return;
        }

        if (this.task && taskType && taskType.id) {
            this.taskDto.type = taskType.id;
            this.taskTypeName = taskType.value;
            this.isTaskTypeChange = false;
            this.isChanged = true;
        }
    }

    private setTaskResult(result: number|undefined) {
        if (!this.taskDto) {
            return;
        }

        this.taskDto.result_type = result;
        this.isComplete = result !== undefined;
        this.isResultChanged = result !== undefined;
        this.isChanged = result !== undefined || this.isChanged;
    }

    private setAssignedTo(assignedToStaffId: number) {
        if (!this.taskDto) {
            return;
        }

        this.taskDto.assigned_to_staff = assignedToStaffId;
        if (assignedToStaffId === enrollmentTeamPlaceholder) {
            this.taskDto.assigned_to_staff = 0;
            this.taskDto.assigned_to_enrollment_team_group = 1;
        } else {
            this.taskDto.assigned_to_enrollment_team_group = 0;
        }
        this.isChanged = true;
    }

    private async watchDueDateChange(dueDateTime: Date|null) {
        if (!this.taskDto) {
            return;
        }

        if (dueDateTime !== null) {
            this.newTzDue = dueDateTime;
            this.taskDto.due_date_time = formatIsoDateTime(dueDateTime, this.timezone);
            this.isValid = true;
            this.isValidDueDate = true;
        } else {
            this.isValid = false;
            this.isValidDueDate = false;
        }
    }

    private saveDueDateChange() {
        if (!this.task) {
            return;
        }

        this.isDueDateChange = false;
        this.isChanged = true;
    }

    private cancelDueDateChange() {
        if (!this.task || !this.taskDto) {
            return;
        }

        this.taskDto.due_date_time = this.task.due_date_time;
        this.chosenTimezone = this.timezone;
        this.isDueDateChange = false;
    }

    private updateDuration(duration: number|undefined) {
        if (!this.taskDto) {
            return;
        }

        this.taskDto.duration = duration;
        this.isChanged = true;
        this.isValid = duration !== undefined;
    }

    private updateAmountInputs() {
        this.isChanged = true;
    }

    private updateUnitInputs() {
        this.isChanged = true;
    }

    private async saveTask() {
        if (!this.taskDto) {
            return;
        }

        this.task = this.task as Task;

        loadingState.loadingIncrement(this.loadingKey);
        if (this.resultDescription) {
            this.taskDto.result_description = this.resultDescription;
        }
        if (this.description) {
            this.taskDto.description = this.description;
        }
        if (this.showTimezones && this.newTzDue) {
            this.taskDto.due_date_time = formatIsoDateTime(this.newTzDue, this.chosenTimezone);
        }
        if (this.isMeeting) {
            if (this.sendEmailReminderGuardian || this.task.reminder_times.primary_guardian_email_reminder !== null) {
                let amount = 0;
                let units = 'second';
                if (this.sendEmailReminderGuardian) {
                    amount = this.guardianEmailReminderAmount !== null ? parseInt(this.guardianEmailReminderAmount) : 0;
                    units = this.guardianEmailReminderUnit !== null ? this.guardianEmailReminderUnit as string : '';
                }
                this.taskDto.primary_guardian_email_reminder_amount = amount;
                this.taskDto.primary_guardian_email_reminder_unit = units;
            }
            if (this.sendTextReminderGuardian || this.task.reminder_times.primary_guardian_text_reminder !== null) {
                let amount = 0;
                let units = 'second';
                if (this.sendTextReminderGuardian) {
                    amount = this.guardianTextReminderAmount !== null ? parseInt(this.guardianTextReminderAmount) : 0;
                    units = this.guardianTextReminderUnit !== null ? this.guardianTextReminderUnit as string : '';
                }
                this.taskDto.primary_guardian_text_reminder_amount = amount;
                this.taskDto.primary_guardian_text_reminder_unit = units;
            }
        }
        if (this.sendEmailReminderStaff || this.task.reminder_times.staff_email_reminder !== null) {
            let amount = 0;
            let units = 'second';
            if (this.sendEmailReminderStaff) {
                amount = this.staffEmailReminderAmount !== null ? parseInt(this.staffEmailReminderAmount) : 0;
                units = this.staffEmailReminderUnit !== null ? this.staffEmailReminderUnit as string : '';
            }
            this.taskDto.staff_email_reminder_amount = amount;
            this.taskDto.staff_email_reminder_unit = units;
        }

        if (this.sendTextReminderStaff || this.task.reminder_times.staff_text_reminder !== null) {
            let amount = 0;
            let units = 'second';
            if (this.sendTextReminderStaff) {
                amount = this.staffTextReminderAmount !== null ? parseInt(this.staffTextReminderAmount) : 0;
                units = this.staffTextReminderUnit !== null ? this.staffTextReminderUnit as string : '';
            }
            this.taskDto.staff_text_reminder_amount = amount;
            this.taskDto.staff_text_reminder_unit = units;
        }
        if (this.isComplete && !this.completedEdit) {
            await this.completeTask(this.taskDto);
        } else {
            if (this.isMeeting) {
                if (this.initialPastDue) {
                    if (!isTaskPastDue(this.taskDto)) {
                        tasksStore.decrementPastDueToursWithin30DaysCount();
                    }
                } else {
                    if (isTaskPastDue(this.taskDto)) {
                        tasksStore.incrementPastDueToursWithin30DaysCount();
                    }
                }
            }
            const updatedTask = await tasksRepository.update(this.taskDto);
            if (this.isMeeting && this.exportToIcsFile) {
                downloadIcsFile(updatedTask);
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
        this.closeModal(true);
    }

    // Save and pop up task modal
    private async saveAddTask() {
        await this.saveTask();
        this.$emit(EventTypes.TASK_ADD_ANOTHER, this.family);
    }

    private async completeTask(task: TaskUpdateDto) {
        task.completed_by_user_id = authStore.userInfoObject?.id; // who did? you did!
        this.taskSavedMessage = `${this.isMeeting ? 'Meeting' : 'Task'} Completed.`;
        await taskUtils.completeTask(task, this.isMeeting, this.isMeeting && this.exportToIcsFile);
        if (this.family) {
            await changeStatus.writePendingChanges(this.family.id);
        }
    }

    private async cancelTask() {
        if (!this.taskDto) {
            return;
        }

        this.$swal({
            text: `Are you sure you want to remove this ${this.taskLabel}?`,
            showConfirmButton: true,
            showCancelButton: true
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                loadingState.loadingIncrement(this.loadingKey);
                this.taskSavedMessage = `${this.isMeeting ? 'Meeting' : 'Task'} Removed.`;
                if (isTaskPastDue(this.taskDto!) && this.isMeeting) {
                    tasksStore.decrementPastDueToursWithin30DaysCount();
                }
                await tasksRepository.cancel(this.taskDto!);
                loadingState.loadingDecrement(this.loadingKey);
                this.closeModal(true);
            }
        });
    }

    private closeModal(taskSaved: boolean) {
        this.taskSaved = taskSaved;
        if (taskSaved) {
            this.$emit(EventTypes.UPDATED, this.task);
        }

        this.isOpen = false;

        this.$emit(EventTypes.CLOSE);
    }

    private async navigateToFamilyHub() {
        if (!this.family) {
            return;
        }

        if (this.newTabStatus) {
            if (this.canAddCrmTabs) {
                await layoutTabsStore.addTab({
                    routeName: 'family-hub',
                    routeParams: { id: this.family.id.toString() },
                    goTo: true,
                    tabTitle: this.family.primary_guardian.last_name
                });
            } else {
                const routeData = this.$router.resolve({
                    name: 'family-hub',
                    params: { id: this.family.id.toString() }
                });
                window.open(routeData.href, '_blank');
            }
        } else {
            await this.$router.push({
                name: 'family-hub',
                params: { id: this.family.id.toString() }
            });
        }
    }

    // Go to the family; confirm if changes have been made to the task
    private async goToFamily() {
        if (!this.family || !this.taskDto) {
            return;
        }

        if (taskUtils.hasTaskBeenModified(this.task!, this.taskDto)) {
            this.$swal({
                text: 'You will lose any unsaved changes. Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Discard Changes',
                cancelButtonText: 'Go Back'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.navigateToFamilyHub();
                }
            });
        } else {
            await this.navigateToFamilyHub();
        }
    }

}
