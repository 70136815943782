









































import { ENROLLMENT_REP_ASSIGNMENT_FLOW_LOCATION } from '@/constants/enrollment-team-constants';
import { EventTypes } from '@/constants/event-type-constants';
import { getSelectedSupportedLocationsTableData } from '@/enrollment-center/enrollment-center-utils';
import { EnrollmentCenterSettingsChangesStore } from '@/enrollment-center/store/enrollment-center-settings-changes-store';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import { StaffPosition, CenterStaffPositionsTableData, CenterEnrollmentRepDto } from '@/staff/models/user';
import { LoadingStore } from '@/store/loading-store';
import { RepositoryFactoryStore } from '@/store/repository-factory-store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { AuthMixin } from '@/auth/auth-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';

const ecCenterSettingsChangesStore = getModule(EnrollmentCenterSettingsChangesStore);
const enrollmentSettingsStore = getModule(EnrollmentCenterSettingsStore);
const loadingStore = getModule(LoadingStore);
const repositoryFactoryStore = getModule(RepositoryFactoryStore);

@Component({
    components: { CenterAscendingStaffList }
})
export default class LocationsSupported extends Mixins(AuthMixin, LocaleMixin) {
    private centerEnrollmentRepsDtoMap: Map<number, CenterEnrollmentRepDto> = new Map();
    private centerEnrollmentReps: Array<CenterStaffPositionsTableData> = [];
    private centerIds: Array<number> = [];
    private centerOriginalEnrollmentRepsMap: Map<number, number | null> = new Map();
    private centerStaffPositionsArray: Array<StaffPosition> = [];
    private isDataSet = false;
    private isStaffLoadingFinished = false;
    private loadedEvent = EventTypes.LOADED;
    private loadingKey = 'LocationsSupported';
    private selectedCentersData: Array<CenterStaffPositionsTableData> = [];

    /**
     * Whether enrollment reps can be set based assignment settings.
     */
    get canSetReps(): boolean {
        return enrollmentSettingsStore.storedSettings?.flow?.id === ENROLLMENT_REP_ASSIGNMENT_FLOW_LOCATION;
    }

    private get headers(): Array<DataTableHeader> {
        let enrollment = this.$t('enrollment').toString();
        enrollment = enrollment.charAt(0).toUpperCase() + enrollment.slice(1);
        const width = this.canSetReps ? '50%' : '100%';
        const headers = [
            { text: 'Select All', value: 'centerName', width: width, sortable: false }
        ];
        if (this.canSetReps) {
            headers.push({ text: `${enrollment} Rep`, value: 'enrollmentRep', width: '50%', sortable: false });
        }

        return headers;
    }

    /**
     * Set the changes to excluded centers.
     */
    @Watch('selectedCentersData', { deep: true })
    private setExcludedCenters() {
        if (this.isDataSet) {
            // Only say that changes have been made after everything has loaded and the user makes the change
            ecCenterSettingsChangesStore.setSaveButtonForSupportedLocations(true);
        }
        // Always set the excluded center ids in the store
        const selectedCenterIds = this.selectedCentersData.map((tableData: CenterStaffPositionsTableData) => {
            return tableData.centerId;
        });
        ecCenterSettingsChangesStore.setExcludedCenters(this.centerIds.filter(centerId => !selectedCenterIds.includes(centerId)));
    }

    private async created() {
        loadingStore.loadingIncrement(this.loadingKey);
        loadingStore.loadingIncrement(this.loadingKey);

        const responses = await Promise.all([
            repositoryFactoryStore.enrollmentCenterSettingsRepository.getExcludedLocations(),
            repositoryFactoryStore.staffRepository.getCenterStaffPositions(),
            enrollmentSettingsStore.init()
        ]);
        this.centerStaffPositionsArray = responses[1];
        this.centerIds = this.centerStaffPositionsArray.map((centerStaffPositions: StaffPosition) => {
            return centerStaffPositions.center.id;
        });
        this.centerEnrollmentReps = this.centerStaffPositionsArray.map((centerStaffPositions: StaffPosition) => {
            this.centerOriginalEnrollmentRepsMap.set(centerStaffPositions.center.id, centerStaffPositions.enrollment_rep?.id ?? null);
            return {
                centerId: centerStaffPositions.center.id,
                centerName: centerStaffPositions.center.values.name,
                enrollmentRep: centerStaffPositions.enrollment_rep?.id ?? null
            };
        });
        this.selectedCentersData = getSelectedSupportedLocationsTableData(this.centerEnrollmentReps, responses[0].center_ids);

        loadingStore.loadingDecrement(this.loadingKey);
        // Make the watch on selectedCentersData run before setting isDataSet to true
        this.$nextTick(() => {
            this.isDataSet = true;
            if (!this.canSetReps) {
                this.finishedLoadingStaff();
            }
        });
    }

    /**
     * Called when the sub components are finished loading (API data).
     */
    private finishedLoadingStaff(): void {
        if (!this.isStaffLoadingFinished) {
            loadingStore.loadingDecrement(this.loadingKey);
            this.isStaffLoadingFinished = true;
        }
    }

    /**
     * Whether or not a center is included/supported.
     *
     * @param row
     */
    private isIncluded(row: CenterStaffPositionsTableData): boolean {
        return this.selectedCentersData.find(item => item.centerId === row.centerId) !== undefined;
    }

    /**
     * Set the enrollment rep for the given center and store the changes in the store.
     *
     * @param userId
     * @param centerId
     */
    private setCenterEnrollmentRep(userId: number | undefined, centerId: number) {
        ecCenterSettingsChangesStore.setSaveButtonForSupportedLocations(true);
        let value = true;
        let dtoUserId = userId;
        if (!userId && !this.centerOriginalEnrollmentRepsMap.get(centerId)) {
            if (this.centerEnrollmentRepsDtoMap.has(centerId)) {
                this.centerEnrollmentRepsDtoMap.delete(centerId);
            }
            return;
        }
        if (!userId) {
            value = false;
            dtoUserId = this.centerOriginalEnrollmentRepsMap.get(centerId) as number;
        }
        const dto: CenterEnrollmentRepDto = {
            userId: dtoUserId as number,
            value: value
        };
        this.centerEnrollmentRepsDtoMap.set(centerId, dto);
        ecCenterSettingsChangesStore.setCenterEnrollmentRepChanges(this.centerEnrollmentRepsDtoMap);
    }
}
