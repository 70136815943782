




























































































// this is a composite component with everything needed for a normal time picker. use v-model to pass in
// time variable
    import { LocaleMixin } from '@/locales/locale-mixin';
    import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
    import BaseTimePicker from '@/components/base/BaseTimePicker.vue';
    import BaseMenu from '@/components/base/BaseMenu.vue';
    import { baseLocales } from '@/locales/locales';
    import { InputValidationRule } from 'vuetify';
    import { getModule } from 'vuex-module-decorators';
    import { FeaturesStore } from '@/features/features-store';

    const featuresState = getModule(FeaturesStore);

    @Component({
        components: { 'base-menu': BaseMenu, 'base-time-picker': BaseTimePicker }
    })
    export default class BaseTimeInput extends Mixins(LocaleMixin) {
        @Prop(String) readonly label: string | undefined;
        @Prop({ default: false }) required!: boolean;
        @Prop({ default: false }) clearable!: boolean;
        @Prop({ default: false }) isDisabled!: boolean;
        @Prop({ default: false }) hideDetails!: boolean | string;
        @Prop({ default: '' }) readonly value!: string | null;
        @Prop() rules!: Array<InputValidationRule> | undefined;
        $refs!: {
            dropdown: HTMLSelectElement;
            menu: Element;
        }

        private timeMenu = false;
        private isValid = false;
        private amPmString = '';
        private items: Array<string> = ['AM', 'PM'];
        private textInputTime = '';
        private searchString = '';

        get amPmRules() {
            return [
                !this.localTime || this.amPmString !== '' || 'Please choose AM or PM'
            ];
        }

        get isLineLeaderEnroll() {
            return featuresState.isLineLeaderEnroll;
        }

        get timeRules() {
            return ([
                !this.required || !!this.localTime || 'Please choose a time',
                !this.localTime || this.isLineLeaderEnroll || this.isValid || 'Please enter a valid time'
            ] as any).concat(this.rules ?? []);
        }

        get localTime() {
            return this.value;
        }

        set localTime(value) {
            this.$emit('input', value);
        }

        get formatHint(): string {
            return baseLocales[this.$i18n.locale].shortTimeFormat;
        }

        /**
         * Auto format as you type
         */
        private autoFormattingTime(event: KeyboardEvent) {
            let input = this.textInputTime;
            if (/\D\/$/.test(input)) {
                input = input.substr(0, input.length - 1);
            }
            if (event.key === 'Backspace' || event.key === 'Delete') {
                return;
            }
            const values = input.split(':').map((v: string) => {
                return v.replace(/\D/g, '');
            });
            if (values[0]) values[0] = this.checkValue(values[0], 12);
            if (values[1]) values[1] = this.checkValue(values[1], 59);
            const output = values.map(function(v, i) {
                return v.length === 2 && i === 0 ? v + ':' : v;
            });
            this.textInputTime = output.join('').substr(0, 5);
        }

        /**
         * Check if the value entered is greater the maximum value allowed, maximum values allowed for hours and minutes in non-military time is 12 and 59
         */
        private checkValue(input: string, max: number): string {
            if (input.charAt(0) !== '0' && max === 12) {
                let num = parseInt(input);
                if (isNaN(num) || num < 0 || num > max) {
                    num = 12;
                }
                input = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
            }
            if (input === '00' && max === 12) {
                return '12';
            }
            return input;
        };

        private exceptionKeysInput(event: KeyboardEvent) {
            if (event.key !== 'Delete' && event.key !== 'Backspace' && event.key !== 'Enter' && event.key !== 'Tab' &&
                event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
                event.preventDefault();
            }
        }

        /**
         * Limit and format the number of characters in the text field
         */
        private limitAndFormat(event: KeyboardEvent) {
            if (this.textInputTime.length >= 5) {
                this.exceptionKeysInput(event);
            } else if (/\D$/.test(event.key)) {
                this.exceptionKeysInput(event);
            } else {
                this.autoFormattingTime(event);
            }
        }

        private limitKeysInputAmPm(event: KeyboardEvent) {
            if (this.searchString.length >= 2) {
                this.exceptionKeysInput(event);
            } else if (/[^AaPpMm]/.test(event.key)) {
                this.exceptionKeysInput(event);
            }
        }

        /**
         * Convert time input from text to localTime value in the time picker
         */
        @Watch('textInputTime', { immediate: true })
        convertToLocalTime(time: string) {
            if (time !== '' && time) {
                this.isValid = /^([0-1][0-9]|1[0-2]):([0-5][0-9])$/.test(time) && time.length === 5;
                if (this.isValid) {
                    const timeArray = time.split(':');
                    let hour = parseInt(timeArray[0], 10);
                    const minute = parseInt(timeArray[1], 10);
                    if (this.amPmString === 'PM' && hour < 12) {
                        hour += 12;
                    }
                    if (this.amPmString === 'AM' && hour === 12) {
                        hour = 0;
                    }
                    const hourString = hour.toString().padStart(2, '0');
                    const minuteString = minute.toString().padStart(2, '0');
                    this.localTime = hour === 12 ? `12:${minuteString}` : `${hourString}:${minuteString}`;
                }
            } else {
                this.localTime = null;
            }
            if (time.length === 5) {
                this.$refs.dropdown.focus();
            }
        }

        /**
         * Convert time input the time picker to the time input from text
         */
        @Watch('localTime', { immediate: true })
        convertToTextInputTime(time: string, old: string) {
            if (time && time !== '') {
                const timeArray = time.split(':');
                let hour = parseInt(timeArray[0], 10);
                const minute = parseInt(timeArray[1], 10);
                if (hour > 12) {
                    hour -= 12;
                    this.amPmString = 'PM';
                } else if (hour === 12) {
                    this.amPmString = 'PM';
                } else {
                    if (hour === 0) {
                        hour = 12;
                    }
                    if (!this.amPmString || old) {
                        this.amPmString = 'AM';
                    }
                }
                const hourString = hour.toString().padStart(2, '0');
                const minuteString = minute.toString().padStart(2, '0');
                this.textInputTime = `${hourString}:${minuteString}`;
            }

        }

     /**
         * Watch for changes from the AM/PM dropdown to change the localTime value in the time picker accordingly
         */
        @Watch('amPmString', { immediate: true })
        timePickerConvert(value: string) {
            if (value && value !== '' && this.localTime) {
                if (this.isValid) {
                    const timeArray = this.textInputTime.split(':');
                    const hour = parseInt(timeArray[0], 10);
                    const minute = parseInt(timeArray[1], 10);
                    if (hour < 12) {
                        if (this.amPmString === this.items[1]) {
                            const timeArrayLocalTime = this.localTime.split(':');
                            const hourLocalTime = parseInt(timeArrayLocalTime[0], 10);
                            if (hourLocalTime < 12) {
                                this.localTime = `${hour + 12}:${minute}`;
                            }
                        } else {
                            this.localTime = this.textInputTime;
                        }
                    } else if (hour === 12) {
                        this.localTime = this.amPmString === this.items[1] ? this.textInputTime : `00:${minute}`;
                    }
                }
            }
        }
    }
