













































































import { AuthMixin } from '@/auth/auth-mixin';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { OrgsUtil } from '@/organizations/orgs-util';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { AuthStore } from '@/store/auth-store';
import { DataTableHeader } from 'vuetify';
import { EmailAttachment } from '@/communications/templates/models/email-attachment';
import { EmailAttachmentsRepository } from '@/communications/templates/repositories/email-attachments-repository';
import { EventTypes } from '@/constants/event-type-constants';
import { OrgsStore } from '@/store/orgs-store';
import EmailAttachmentUpload from '@/communications/attachments/components/EmailAttachmentUpload.vue';
import ManageEmailAttachmentModal from '@/communications/attachments/components/ManageEmailAttachmentModal.vue';
import store from '@/store';
import { Route } from 'vue-router';
import { ApiParameters } from '@/repositories/abstract-repository';

const authStore = getModule(AuthStore, store);
const appStore = getModule(AppStateStore);
const emailAttachmentsRepo = new EmailAttachmentsRepository();
const featuresStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore, store);
const organizationsStore = getModule(OrgsStore);
const orgsUtil = new OrgsUtil();
const staffUtils = new StaffUtils();

Component.registerHooks([
    'beforeRouteEnter'
]);
@Component({
    components: { ManageEmailAttachmentModal, EmailAttachmentUpload }
})
export default class EmailAttachmentsTable extends Mixins(AuthMixin, LocaleMixin) {
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        const canViewTemplatesRemindersAttachments = await staffUtils.getUserPermission(PermissionName.AutomationViewMessageTemplates);
        if (canViewTemplatesRemindersAttachments) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    private tableHeaders: Array<DataTableHeader> = [
        { text: 'File Name', value: 'filename' },
        { text: 'Location(s) Available', value: 'org.values.name' },
        { text: 'File Size', value: 'file_size' },
        { text: 'Uploaded', value: 'created_datetime' },
        { text: 'Uploaded By', value: 'created_by_staff' },
        { text: '', value: 'edit' }
    ];

    private automationMessageTemplatesPermissionGrant = false;
    private closed = EventTypes.CLOSE;
    private items: Array<EmailAttachment> = [];
    private loadingKey = EmailAttachmentsTable.name;
    private managedEmailAttachment: EmailAttachment | null = null;
    private manageOpen = false;
    private updated = EventTypes.UPDATED;
    private search = '';
    private currentItemCount = 1;
    private allItemsCount = 1;

    /**
     * Whether an attachment can be added by the user.
     */
    get canAddAttachment() {
        if (this.isSuperUser) {
            return true;
        }

        if (!this.isCrmPlus) {
            return true;
        }

        return this.automationMessageTemplatesPermissionGrant;
    }

    private get currentOrgId(): number {
        let userOrg = null;
        if (appStore.storedCurrentOrg) {
            userOrg = appStore.storedCurrentOrg.id;
        }

        if (!userOrg && authStore.userInfoObject && authStore.userInfoObject.org_id > 0) {
            userOrg = authStore.userInfoObject.org_id;
        }

        if (!userOrg && appStore.storedCurrentCenter) {
            userOrg = appStore.storedCurrentCenter.organization_id;
        }

        // Getters can't be async, so we can't get the org from the center without a promise.
        // Hopefully this will fix this issue seen on QA on first load, where the user is assumed to be at org id 1.
        return userOrg ?? 0;
    }

    /**
     * Whether or not the db is in crm plus mode.
     */
    get isCrmPlus() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    @Watch('currentOrgId', { immediate: true })
    private async loadAttachments() {
        loadingState.loadingIncrement(this.loadingKey);

        if (this.currentOrgId > 0) {
            const extraParams: ApiParameters = {
                'no-pagination': true,
                orgId: this.currentOrgId,
                include_child_orgs: true
            };
            if (this.search) {
                extraParams.search = this.search;
            }
            const response = await emailAttachmentsRepo.get(null, extraParams);

            const items = response.entities;
            for (const item in items) {
                items[item].can_edit = await this.canEditAttachment(items[item]);
            }

            this.items = items;
            this.currentItemCount = Number(response.count);
            if (!this.search) {
                this.allItemsCount = this.currentItemCount;
            }
        }
        loadingState.loadingStop(this.loadingKey);
    }

    async created() {
        if (!organizationsStore.stored || organizationsStore.stored.length < 0) {
            await organizationsStore.init();
        }
        await featuresStore.init();
        if (this.isCrmPlus) {
            this.automationMessageTemplatesPermissionGrant = await staffUtils.getUserPermission(PermissionName.AutomationMessageTemplates);
        }
    }

    /**
     * Can the user edit this attachment?
     */
    public async canEditAttachment(attachment: EmailAttachment): Promise<boolean> {
        if (authStore.userInfoObject !== undefined && authStore.userInfoObject) {
            const hasAccess = await orgsUtil.canUserAccessOrg(attachment.org.id);
            const hasPermission = this.isCrmPlus ? this.automationMessageTemplatesPermissionGrant : true;

            return (hasAccess && hasPermission) || (attachment.created_by_staff && attachment.created_by_staff.id === authStore.userInfoObject.id);
        }

        return false;
    }

    /**
     * Handle clicking on the edit attachment button.
     */
    private editAttachment(attachment: EmailAttachment): void {
        if (attachment.id) {
            this.managedEmailAttachment = attachment;
            this.manageOpen = true;
        }
    }

    private managedClosed() {
        this.managedEmailAttachment = null;
    }

    private async clearSearch() {
        // Update items, no search term.
        this.search = '';
        await this.loadAttachments();
    }

    private async doSearch() {
        if (!this.search) {
            // Update items, no search term.
            await this.loadAttachments();
            return;
        }

        if (this.search.length < 3) {
            // Do not search unless we have at least 3 characters
            return;
        }

        await this.loadAttachments();
    }
}
