import {
    IncomingMessage,
    Message,
    MessageDirection,
    MessageThread,
    ThreadMeta
} from '@/communications/messages/models/message';
import { Email } from '@/communications/messages/models/email';
import { TextMessage } from '@/communications/messages/models/text';
import { FacebookMessage } from '@/communications/messages/models/facebookMessage';

export function getIncomingFromThread(thread: Array<Email | TextMessage | FacebookMessage>): Message&IncomingMessage | null {
    for (const message of thread) {
        if (message.type === MessageDirection.INCOMING) {
            return message;
        }
    }
    return null;
}

export function getUsersParticipated(thread: Array<Email | TextMessage | FacebookMessage>): Set<number> {
    const userIds = new Set<number>();
    for (const message of thread) {
        if (message.type === MessageDirection.OUTGOING) {
            if (message.send_by_user) {
                userIds.add(message.send_by_user.id);
            }
        }
    }
    return userIds;
}

export function buildThreadMeta(threads: Array<MessageThread>, isText: boolean, isEmail: boolean): Array<ThreadMeta|any> {
    const ret: ThreadMeta[]|any[] = [];
    threads.map(
        (thread) => {
            const incoming = getIncomingFromThread(thread);
            const familyId = incoming?.send_to_guardian?.values?.family_id ?? thread?.[0].send_to_guardian?.values?.family_id ?? 0;
            ret.push({
                thread,
                is_dismissed: incoming?.is_dismissed ?? false,
                is_read: incoming?.is_read ?? false,
                incoming_id: incoming?.id ?? 0,
                family_id: familyId,
                is_text: isText,
                is_email: isEmail,
                sent_date_time: incoming?.sent_date_time ?? thread?.[0].sent_date_time
            });
        }
    );

    return ret;
}
