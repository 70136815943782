import { PstToursAvailability, PstToursAvailabilityTimeRange } from '@/pst-tours/models/pst-tours-availability';
import { isAfter, isBefore, isEqual, parseISO } from 'date-fns';
import { addMinutes } from '@/date-time/date-time-utils';

/**
 * Retrieve continuous availability range by date.
 * @param availabilities
 */
export function getContinuousAvailabilityRangesByDate(
    availabilities: Array<PstToursAvailability>
): Map<string, Array<PstToursAvailabilityTimeRange>> {
    const availabilityMap = new Map<string, Array<PstToursAvailabilityTimeRange>>();
    let currentStart: string | null = null;

    availabilities.forEach((availability, index) => {
        const dateKey = availability.start_datetime.split('T')[0];

        // Initialize the map for this date if it doesn't exist
        if (!availabilityMap.has(dateKey)) {
            availabilityMap.set(dateKey, []);
        }

        const currentRanges = availabilityMap.get(dateKey)!;

        if (availability.is_available) {
            if (!currentStart) {
                // Start a new range when the first available slot is found
                currentStart = availability.start_datetime;
            }

            // If it's the last element and still available, close the range
            if (index === availabilities.length - 1 || availabilities[index + 1].start_datetime.split('T')[0] !== dateKey || !availabilities[index + 1].is_available) {
                currentRanges.push({
                    start_datetime: currentStart,
                    end_datetime: availability.end_datetime
                });
                currentStart = null; // Reset after adding the final range for the day
            }
        } else if (currentStart) {
            // Close the current range when an unavailable slot is encountered
            currentRanges.push({
                start_datetime: currentStart,
                end_datetime: availabilities[index - 1].end_datetime
            });
            currentStart = null; // Reset for the next range
        }
    });

    return availabilityMap;
}

/**
 * Check if a datetime is within a range.
 * @param datetime
 * @param range
 */
export function isWithinRange(datetime: string, range: PstToursAvailabilityTimeRange): boolean {
    const datetimeAsDate = parseISO(datetime);
    const rangeStartAsDate = parseISO(range.start_datetime);
    const rangeEndAsDate = parseISO(range.end_datetime);

    return (isEqual(datetimeAsDate, rangeStartAsDate) || isAfter(datetimeAsDate, rangeStartAsDate)) &&
           (isEqual(datetimeAsDate, rangeEndAsDate) || isBefore(datetimeAsDate, rangeEndAsDate));
}

/**
 * Check if a tour is within any of the availability ranges.
 * @param availabilityRanges
 * @param startDateTime
 * @param duration
 */
export function isTourInRange(
    availabilityRanges: Array<PstToursAvailabilityTimeRange>,
    startDateTime: string,
    duration: number
): boolean {

    const endDateTime = addMinutes(startDateTime, duration);

    // Loop through the availability ranges to check if the task lies within any range
    for (const range of availabilityRanges) {
        const isStartWithinRange = isWithinRange(startDateTime, range);
        const isEndWithinRange = isWithinRange(endDateTime, range);

        if (isStartWithinRange && isEndWithinRange) {
            return true;
        }
    }

    return false;
}
