




























































































































































import { EventTypes } from '@/constants/event-type-constants';
import { AddMeetingEventPayload, EditMeetingEventPayload } from '@/tasks/models/tour-calendar-models';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
    toursToCalendarEvents,
    nylasEventsToCalendarEvents,
    pstToursAvailabilityEventsToCalendarEvents
} from '@/calendar/calendar-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import { AppStateStore } from '@/store/app-state-store';
import { AuthStore } from '@/store/auth-store';
import { OrgsStore } from '@/store/orgs-store';
import { LoadingStore } from '@/store/loading-store';
import { TaskFiltersParameters, TasksRepository } from '@/tasks/repositories/tasks-repository';
import type { Task } from '@/tasks/models/task-models';
import FullCalendar, {
    DateSelectArg,
    EventClickArg,
    EventApi,
    CalendarApi,
    DatesSetArg,
    EventInput
} from '@fullcalendar/vue';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import ManageTaskModal from '@/tasks/components/ManageTaskModal.vue';
import store from '@/store';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import {
    addDays, diffMinutes,
    formatDateWithTimezone,
    getTodayEnd,
    getTodayStart, isDateInPast,
    isoFormatLong
} from '@/date-time/date-time-utils';
import type { Org } from '@/models/organization/org';

import { ApiPagination } from '@/repositories/abstract-repository';
import { Family } from '@/families/models/family';
import LocationDashboardBulkActionsButton from '@/dashboards/components/LocationDashboardTabs/LocationDashboardBulkActionsButton.vue';
import { ActionItem, ActionItemResultCounts, BulkActionsTypes } from '@/dashboards/models/action-items-models';
import LocationDashboardBulkActionsModal from '@/dashboards/components/LocationDashboardTabs/LocationDashboardBulkActionsModal.vue';
import ActionItemsRepository, { ActionItemsParams } from '@/dashboards/repositories/action-items-repository';
import { TaskTypeCalendar } from '@/tasks/task-type-constants';
import { NylasRepository } from '@/integrations/repositories/nylas-repository';
import ManageTasks from '@/tasks/components/ManageTasks.vue';
import { OrgsUtil } from '@/organizations/orgs-util';
import { PstToursAvailabilityRepository } from '@/pst-tours/repositories/pst-tours-availability-repository';
import { getContinuousAvailabilityRangesByDate } from '@/pst-tours/pst-tours-utils';
import { PstToursAvailabilityTimeRange } from '@/pst-tours/models/pst-tours-availability';

const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const orgsState = getModule(OrgsStore);
const tasksRepo = new TasksRepository();
const actionItemsRepo = new ActionItemsRepository();
const nylasRepo = new NylasRepository();
const orgsUtil = new OrgsUtil();
const pstToursAvailabilityRepo = new PstToursAvailabilityRepository();

@Component({
    components: {
        AddTaskModal,
        ManageTaskModal,
        ManageTasks,
        FullCalendar, // make the <FullCalendar> tag available
        LocationDashboardBulkActionsButton,
        LocationDashboardBulkActionsModal
    }
})
export default class TourCalendar extends Mixins(LocaleMixin) {
    /**
     * Whether an existing meeting should be allowed to be edited.
     */
    @Prop({ default: true }) readonly canOpenTaskModals!: boolean;
    @Prop({ default: false }) readonly isSelected!: boolean;
    @Prop({ default: false }) readonly newAddPlacement!: boolean;

    /**
     *  Show the Tours of everyone unless it is defined otherwise
     */
    @Prop({ default: false }) readonly retrieveOnlyMyTours!: boolean;
    @Prop({ default: '' }) search!: string;

    /*
     * Don't show search if calendar is opened as a modal
     */
    @Prop({ default: true }) readonly showSearchBar!: boolean;
    @Prop({ default: null }) readonly familyCenterId!: number | null;
    @Prop({ default: false }) readonly etDashMode!: boolean;

    $refs!: {
        calendar: any;
    };

    private timezone = authState.userTimeZone;
    private isMeetingSelected = false;
    private isMeeting = true;
    private selectedTask: Task | null = null;
    private key = 0;
    private isAddTask = false;
    private startDateTime: string | null = null;
    private duration = 0;
    private inputValue: string | null = '';
    private selectedLocation: Org | null = null;
    private showLocationSelect = true;
    private closeEvent = EventTypes.CLOSE;
    private searchString = '';
    private addAnotherTaskEvent = EventTypes.TASK_ADD_ANOTHER;
    private taskFamily: Family | null = null;
    private lastSelectedTask: Task | null = null;
    private calendarEventsPastDue: ActionItem[] = [];
    private resultsCount: ActionItemResultCounts = {
        items: 0,
        future: 0,
        past: 0,
        today: 0,
        total: 0
    };

    private updatedEvent = EventTypes.UPDATED;
    private showBulkActionsModal = false;
    private actionType: BulkActionsTypes | null = null;
    private availabilityByDateMap: Map<string, Array<PstToursAvailabilityTimeRange>> = new Map();

    get calendarOptions() {
        return {
            plugins: [
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin // needed for dateClick
            ],
            headerToolbar: {
                left: 'today',
                center: 'prev,title,next',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            views: {
                dayGridMonth: {
                    selectable: false
                },
                timeGridWeek: {
                    titleFormat: {
                        day: 'numeric',
                        month: 'short'
                    }
                }
            },
            eventTimeFormat: {
                hour: '2-digit',
                minute: '2-digit',
                meridiem: true
            },
            displayEventEnd: false,
            contentHeight: 600,
            navLinks: true,
            firstDay: this.firstDay,
            initialView: 'timeGridWeek',
            allDaySlot: false,
            viewHeight: '45vh',
            selectable: true,
            selectMirror: false,
            dayMaxEvents: true,
            weekends: true,
            select: this.handleDateSelect,
            eventClick: this.handleEventClick,
            eventDidMount: this.eventRender,
            eventClassNames: this.classNamesCallback,
            nowIndicator: true,
            timeZone: this.timezone,
            locales: allLocales,
            locale: this.fullCalendarLocale,
            events: this.retrieveCalendarEvents,
            now: formatDateWithTimezone(new Date(), authState.userTimeZone, 'yyyy-MM-dd HH:mm:ss'),
            scrollTime: new Date(this.scrollTimeValue).toLocaleTimeString('en-GB'),
            datesSet: this.viewChanged,
            loading: function(isLoading: boolean) {
                if (isLoading) {
                    loadingState.loadingIncrement('tourCalendar');
                } else {
                    loadingState.loadingDecrement('tourCalendar');
                }
            }
        };
    };

    get centerId() {
        return this.selectedLocation?.center?.id ?? null;
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    get orgs() {
        if (!this.org) {
            return orgsState.stored.filter(org => org.center !== null);
        }
        if (this.familyCenterId) {
            return orgsState.stored.filter(org => org.center?.id === this.familyCenterId);
        }
        return orgsState.orgCentersByOrgId(this.org.id);
    }

    get scrollTimeValue(): number {
        const date = new Date(formatDateWithTimezone(new Date(), authState.userTimeZone, 'yyyy-MM-dd HH:mm:ss'));
        return date.setHours(date.getHours() - 1);
    }

    get locationValue(): string | undefined {
        if (!this.org) {
            return '';
        } else if (this.selectedLocation) {
            return this.selectedLocation.id ? '' + this.selectedLocation.id : '' + this.selectedLocation;
        } else {
            return '' + orgsUtil.getOrgWithLowestId(this.orgs).id;
        }
    }

    get showPstToursAvailability() {
        return appState.displayPstAvailableHours;
    }

    get pstToursAvailabilityTimeRanges(): Array<PstToursAvailabilityTimeRange> {
        if (this.showPstToursAvailability && this.startDateTime) {
            const dateString = this.startDateTime.substring(0, 10);
            return this.availabilityByDateMap.get(dateString) || [];
        }
        return [];
    }

    async mounted() {
        // hide location select if logged in user is center-level
        if (authState.userInfoObject?.center_id !== null) {
            this.showLocationSelect = false;
        }
        // preselect family location if given
        if (this.familyCenterId) {
            const familyOrg = this.orgs.find(org => org.center?.id === this.familyCenterId);
            if (familyOrg) {
                this.selectedLocation = familyOrg;
                return;
            }
        }
        // sync location select with blue bar center select choice, but only if center level.
        if (this.org?.center) {
            this.selectedLocation = this.org;
        } else {
            this.selectedLocation = orgsUtil.getOrgWithLowestId(this.orgs);
        }
    }

    @Watch('org')
    private async updateOrg(org: Org) {
        if (org && org.id > 0) {
            // sync location select with blue bar center select choice, but only if center level.
            if (org.center !== null) {
                this.selectedLocation = org;
            } else {
                this.selectedLocation = orgsUtil.getOrgWithLowestId(this.orgs);
            }
            await this.updateToursCount();
            (this.$refs.calendar.getApi() as CalendarApi).refetchEvents();
        }
    }

    @Watch('retrieveOnlyMyTours')
    private async updateToursCount() {
        const pagination: ApiPagination = {
            limit: 1,
            offset: 0
        };
        const extraParams: TaskFiltersParameters = {
            org_id: this.locationValue,
            include_completed: false
        };

        if (this.retrieveOnlyMyTours) {
            if (authState.userInfoObject) {
                extraParams.assigned_to = authState.userInfoObject.id;
            }
        }

        const todayStart = getTodayStart(this.timezone);
        const todayEnd = getTodayEnd(this.timezone);

        const tours = await tasksRepo.getToursInRange(todayStart, todayEnd, pagination, extraParams, true);
        this.$emit(EventTypes.TOURS_COUNT, tours.count);
    }

    @Watch('selectedLocation')
    private async updateLocation() {
        if (this.selectedLocation) {
            await this.updateToursCount();
            (this.$refs.calendar.getApi() as CalendarApi).refetchEvents();
        }
    }

    @Watch('isSelected')
    private reRender() {
        if (this.isSelected) {
            window.dispatchEvent(new Event('resize'));
        }
    }

    @Watch('showPstToursAvailability')
    private async updatePstToursAvailability() {
        (this.$refs.calendar.getApi() as CalendarApi).refetchEvents();
    }

    classNamesCallback(info: { event: EventApi }) {
        if (info.event.extendedProps) {
            if (info.event.extendedProps.pastDue) {
                return ['tour-calendar-' + info.event.extendedProps.group.toLowerCase() + '-past-due'];
            }

            return ['tour-calendar-' + info.event.extendedProps.group.toLowerCase()];
        }

        return [];
    }

    eventRender(info: { event: EventApi; el: HTMLElement; timeText: string }) {
        info.el.setAttribute('title', info.timeText + ' ' + info.event.title);
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        this.startDateTime = formatDateWithTimezone(selectInfo.start, 'UTC', isoFormatLong);
        this.duration = diffMinutes(selectInfo.end, selectInfo.start);
        if (!this.canOpenTaskModals) {
            this.$emit(
                EventTypes.MEETING_ADD,
                {
                    startDateTime: this.startDateTime,
                    duration: this.duration,
                    pstToursAvailabilityTimeRanges: this.pstToursAvailabilityTimeRanges
                } as AddMeetingEventPayload
            );
            return;
        }

        this.isAddTask = true;
    }

    handleEventClick(clickInfo: EventClickArg) {
        if (clickInfo.event.extendedProps.group === 'external' || clickInfo.event.extendedProps.group === 'pst-tours-availability') {
            // For not don't do a thing!
            return;
        }

        this.selectedTask = clickInfo.event.extendedProps.meeting as Task;
        if (!this.canOpenTaskModals) {
            this.$emit(EventTypes.MEETING_EDIT, { selectedTask: this.selectedTask } as EditMeetingEventPayload);
            return;
        }

        this.isMeetingSelected = true;
    }

    async retrieveTours(arg: { start: Date; end: Date }): Promise<EventInput[] | never[]> {
        if (!this.org) {
            return [];
        }
        await this.updateToursCount();

        // Get the tours for the calendar.
        const tours = await tasksRepo.getToursInRange(
            addDays(arg.start, -2),
            addDays(arg.end, 2),
            null,
            { org_id: this.locationValue },
            true
        );

        await this.getAllTours();

        return toursToCalendarEvents(tours.entities, this.timezone);
    };

    async retrieveMyTours(arg: { start: Date; end: Date }): Promise<EventInput[] | never[]> {
        if (!this.org) {
            return [];
        }

        await this.updateToursCount();

        let tours: Array<Task> = [];
        if (authState.userInfoObject) {
            const toursResponse = await tasksRepo.getToursInRange(
                addDays(arg.start, -2),
                addDays(arg.end, 2),
                null,
                {
                    org_id: this.locationValue,
                    assigned_to: authState.userInfoObject.id
                },
                true
            );
            tours = toursResponse.entities;
            await this.getAllTours();
        }

        return toursToCalendarEvents(tours, this.timezone);
    };

    async retrieveExternalEvents(arg: { start: Date; end: Date }): Promise<EventInput[] | never[]> {
        if (!this.org) {
            return [];
        }

        const events = await nylasRepo.getCalendarEvents(
            addDays(arg.start, -2),
            addDays(arg.end, 2),
            {
                org_id: this.locationValue
            },
            true
        );

        return nylasEventsToCalendarEvents(events, this.timezone);
    }

    async retrievePstTourAvailability(arg: { start: Date; end: Date }): Promise<EventInput[] | never[]> {
        if (!this.org) {
            return [];
        }

        const startDate = new Date(arg.start);
        startDate.setHours(0, 0, 0, 0);
        let startDateTime = addDays(startDate, -2);

        const endDate = new Date(arg.end);
        endDate.setHours(23, 59, 59, 999);
        let endDateTime = addDays(endDate, 2);

        if (!this.centerId || isDateInPast(endDateTime)) {
            return [];
        }

        startDateTime = startDateTime.replace('T', ' ').substring(0, 19);
        endDateTime = endDateTime.replace('T', ' ').substring(0, 19);
        const availabilities = await pstToursAvailabilityRepo.retrieveAll(
            this.centerId,
            startDateTime,
            endDateTime
        );
        this.availabilityByDateMap = getContinuousAvailabilityRangesByDate(availabilities);
        return pstToursAvailabilityEventsToCalendarEvents(this.availabilityByDateMap, this.timezone);
    }

    async retrieveCalendarEvents(arg: { start: Date; end: Date }): Promise<EventInput[]> {
        const events = this.retrieveOnlyMyTours
            ? await this.retrieveMyTours(arg)
            : await this.retrieveTours(arg);
        const externalEvents = await this.retrieveExternalEvents(arg);
        const pstToursAvailabilityEvents = this.showPstToursAvailability ? await this.retrievePstTourAvailability(arg) : [];

        return [...events, ...externalEvents, ...pstToursAvailabilityEvents];
    }

    selectTaskFromSearch(task: Task) {
        // open manage task modal from events passed up from table
        this.selectedTask = task;
        this.isMeetingSelected = true;
    }

    async getAllTours() {
        const orgId = Number(this.locationValue);
        const itemsParams: ActionItemsParams = {
            org_id: orgId,
            only_mine: this.retrieveOnlyMyTours,
            non_tasks_dash_mode: false,
            tasks_dash_mode: true,
            include_meetings: true,
            task_dash_dates: ['past'],
            task_type_filter: TaskTypeCalendar.TOUR
        };
        const { items: tours } = await actionItemsRepo.getActionItems(itemsParams);
        const itemsParamsMeetings = { ...itemsParams, task_type_filter: TaskTypeCalendar.MEETING };
        const { items: meetings } = await actionItemsRepo.getActionItems(itemsParamsMeetings);
        this.calendarEventsPastDue = [...tours, ...meetings];
        this.resultsCount.items = this.calendarEventsPastDue.length;
        this.resultsCount.past = this.calendarEventsPastDue.length;
    }

    async updateCalendar() {
        await this.updateToursCount();
        (this.$refs.calendar.getApi() as CalendarApi).refetchEvents();
    }

    async createCalendar() {
        await this.updateCalendar();
        this.isMeeting = true;
    }

    @Watch('search')
    updateSearchString() {
        if (this.search && this.search.length > 2) {
            this.searchString = this.search;
        } else {
            this.searchString = '';
        }
    }

    updateSearch() {
        if (this.inputValue && this.inputValue.length > 2) {
            this.searchString = this.inputValue;
        } else {
            this.searchString = '';
        }
    }

    clearSearch() {
        this.searchString = '';
        this.inputValue = '';
    }

    closeManageTaskModal() {
        this.lastSelectedTask = this.selectedTask;
        this.selectedTask = null;
    }

    closeAddTaskModal() {
        this.isAddTask = false;
    }

    addAnotherTask(family: Family) {
        this.isMeeting = false;
        this.isMeetingSelected = false;
        this.selectedTask = this.lastSelectedTask;
        this.taskFamily = family;
        this.isAddTask = true;
    }

    handleAction(type: BulkActionsTypes) {
        this.actionType = type;
        if (this.actionType === BulkActionsTypes.PAST_DUE_MEETINGS || this.actionType === BulkActionsTypes.PAST_DUE_TOURS) {
            this.showBulkActionsModal = true;
        }
    }

    async loadData() {
        loadingState.loadingIncrement('tourCalendar');
        await this.updateCalendar();
        loadingState.loadingDecrement('tourCalendar');
    }

    viewChanged({ view }: DatesSetArg) {
        const calendarView = document.querySelector('.fc-view-harness.fc-view-harness-active') as HTMLElement;
        if (calendarView) {
            // Temporarily set the height to 1000px to allow the calendar to render all existing events before refresh without breaking
            calendarView.style.height = view.type === 'dayGridMonth' ? '1000px' : '600px';
        }
        view.calendar.setOption('contentHeight', view.type === 'dayGridMonth' ? 'auto' : 600);
        view.calendar.updateSize();
    }
}
