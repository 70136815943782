
































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import {
    EmailAttachment,
    EmailAttachmentCreateDto,
    EmailAttachmentUpdateDto
} from '@/communications/templates/models/email-attachment';
import { EmailAttachmentMapper } from '@/communications/mappers/email-attachment-mapper';
import { EmailAttachmentsRepository } from '@/communications/templates/repositories/email-attachments-repository';
import { AuthStore } from '@/store/auth-store';
import { clone, isEqual } from 'lodash';
import { encodeFile, openFile } from '@/core/file-utils';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Org } from '@/models/organization/org';
import { OrgsRepository } from '@/repositories/orgs-repository';
import store from '@/store';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const centersStore = getModule(CentersStore);
const emailAttachmentMapper = new EmailAttachmentMapper();
const emailAttachmentRepo = new EmailAttachmentsRepository();
const organizationRepo = new OrgsRepository();

@Component({
    components: { BaseClose, SaveButton }
})
export default class ManageEmailAttachmentModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    // v-model whether we should show the modal
    @Prop({ default: false }) readonly value!: boolean;
    @Prop() readonly emailAttachment!: EmailAttachment | null;
    @Ref('form') readonly form!: VForm;

    private emailAttachmentDto: EmailAttachmentCreateDto | EmailAttachmentUpdateDto = emailAttachmentMapper.createNewDto();
    private file: File | null = null;
    private hideOrganizations = false;
    private loadingKey = 'manageEmailAttachment';
    private organizations: Array<Org> = [];
    private original: EmailAttachmentCreateDto | EmailAttachmentUpdateDto = emailAttachmentMapper.createNewDto();
    private validForm = false;

    //  Getters / Setters.
    private get isAdd() {
        return !this.emailAttachment;
    }

    get isValid(): boolean {
        if (this.isAdd) {
            return !(!this.file || !this.file.name);
        }

        return this.validForm && !isEqual(this.original, this.emailAttachmentDto);
    }

    // Handles showing the modal
    private get modelValue(): boolean {
        return this.value;
    }

    private set modelValue(showIt: boolean) {
        this.$emit('input', showIt);
    }

    // private get organizations() {
    //     return await organizationRepo.get(null, { include_parent_orgs: 1 });
    // }

    @Watch('modelValue', { immediate: true })
    private async modalActivated() {
        if (this.modelValue && authState.userInfoObject) {
            this.hideOrganizations = false;
            const orgId = authState.userInfoObject.org_id;

            if (authState.userInfoObject.center_id !== null && authState.userInfoObject.center_id > 0) {
                const center = await centersStore.getById(authState.userInfoObject.center_id);
                if (center.organization_id === orgId) {
                    this.hideOrganizations = true;
                }
            }

            if (this.emailAttachment) {
                this.emailAttachmentDto = emailAttachmentMapper.toUpdateDto(this.emailAttachment);
                this.$nextTick(() => {
                    // Validate form when we popup the modal.
                    this.form.validate();
                });
            } else {
                this.emailAttachmentDto = emailAttachmentMapper.createNewDto();
                this.emailAttachmentDto.org = orgId || 1;
            }

            this.original = clone(this.emailAttachmentDto);
        }
    }

    // Life-cycle events.
    async created() {
        this.organizations = (await organizationRepo.get(null, { include_parent_orgs: 1 })).entities;
    }

    // Methods.
    private async save() {
        loadingState.loadingIncrement(this.loadingKey);

        if (this.isAdd) {
            if (!this.file || !this.file.name) {
                loadingState.loadingDecrement(this.loadingKey);
                throw new Error('Unable to transmit this file.');
            }

            const fileContents = await encodeFile(this.file);
            this.emailAttachmentDto.filename = this.file.name;
            this.emailAttachmentDto.file = fileContents as string;
            await emailAttachmentRepo.create(this.emailAttachmentDto);
        } else {
            await emailAttachmentRepo.patchOne(this.emailAttachmentDto.id as number, this.emailAttachmentDto);
        }

        loadingState.loadingDecrement(this.loadingKey);

        this.$emit(EventTypes.UPDATED);
        this.close();
    }

    private close() {
        this.$emit(EventTypes.CLOSE);
        this.form.reset();
        this.modelValue = false;
    }

    private async deleteAttachment() {
        if (!this.isAdd && this.emailAttachment) {
            const result = await this.$swal({
                icon: 'warning',
                text: 'WARNING - deleting this attachment is not reversible (though you could upload it again).  Are you sure?',
                showCancelButton: true,
                confirmButtonText: 'DELETE',
                focusConfirm: false,
                cancelButtonText: 'CANCEL',
                focusCancel: true,
                reverseButtons: true,
                customClass: {
                    cancelButton: 'swal2-primary-button-styling',
                    confirmButton: 'swal2-secondary-button-styling'
                }
            });

            if (result.isConfirmed) {
                loadingState.loadingIncrement(this.loadingKey);
                await emailAttachmentRepo.delete(this.emailAttachment);
                loadingState.loadingDecrement(this.loadingKey);

                this.$emit(EventTypes.UPDATED);
            } else {
                return;
            }
        }

        this.close();
    }

    private async openAttachment() {
        loadingState.loadingIncrement(this.loadingKey);
        const fileContent = await emailAttachmentRepo.getFile(this.emailAttachment!);
        openFile(fileContent, this.emailAttachment!.filename, this.emailAttachment!.mime_type);
        loadingState.loadingDecrement(this.loadingKey);
    }
}
