import { getTimeZoneString } from '@/date-time/date-time-utils';
import { isNaN } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import {
    formatDateForLocale, formatDateYearFirstForLocale, formatDistanceForLocale,
    formatPhoneForLocale, formatShortDateForLocale, formatShortDateYearForLocale, formatShortTimeForLocale,
    formatTimeForLocale, formatMilitaryTimeForLocale,
    getFirstDayOfWeekForLocale, getFullCalendarLocaleForLocale, getPhoneLocale
} from '@/locales/locale-helpers';
import { UserStub } from '@/staff/models/user';
import MiscUtils from '@/utils/misc-utils';

// extend this as a Mixin to use these helper functions in a component
@Component
export class LocaleMixin extends Vue {
    get firstDay(): number {
        return getFirstDayOfWeekForLocale(this.$i18n.locale);
    }

    get fullCalendarLocale(): string {
        return getFullCalendarLocaleForLocale(this.$i18n.locale);
    }

    get currencyDecimalSeparator(): string {
        return this.$n(0.00, 'currency').substring(2, 3);
    }

    get currencySymbol(): string {
        return this.$n(0, 'currency').substring(0, 1);
    }

    get currencyThousandsSeparator(): string {
        const value = this.$n(1000.00, 'currency').substring(2, 3);
        return isNaN(parseInt(value)) ? value : '';
    }

    get phoneLocale(): string {
        return getPhoneLocale(this.$i18n.locale);
    }

    get isAustralianLocale(): boolean {
        return this.$i18n.locale === 'en-AU';
    }

    formatDistance(distance: number, digits = 1): string {
        return formatDistanceForLocale(this.$i18n.locale, distance, digits);
    }

    formatPhone(phoneNumber: string): string {
        return formatPhoneForLocale(this.$i18n.locale, phoneNumber);
    }

    formatDate(date: string | Date, timezone: string | null = null): string {
        return formatDateForLocale(this.$i18n.locale, date, timezone);
    }

    formatDateYearFirst(date: string | Date, timezone: string | null = null): string {
        return formatDateYearFirstForLocale(this.$i18n.locale, date, timezone);
    }

    formatShortDate(date: string | Date, timezone: string | null = null): string {
        return formatShortDateForLocale(this.$i18n.locale, date, timezone);
    }

    formatShortDateYear(date: string | Date, timezone: string | null = null): string {
        return formatShortDateYearForLocale(this.$i18n.locale, date, timezone);
    }

    formatTime(time: string | Date, timezone: string | null = null): string {
        return formatTimeForLocale(this.$i18n.locale, time, timezone);
    }

    formatMilitaryTime(time: string | Date, timezone: string | null = null): string {
        return formatMilitaryTimeForLocale(this.$i18n.locale, time, timezone);
    }

    formatDateTime(date: string | Date, timezone: string | null = null): string {
        return this.formatDate(date, timezone) + ' ' + this.formatTime(date, timezone);
    }

    /**
     * Get the datetime string with the timezone string appended at the end.
     *
     * @param date
     * @param timezone
     */
    formatDateTimeWithTimezone(date: string | Date, timezone: string | null = null): string {
        return this.formatDate(date, timezone) + ' ' +
            this.formatTime(date, timezone) + ' ' +
            getTimeZoneString(new Date(), timezone ?? 'UTC', this.$i18n.locale);
    }

    formatShortDateTimeWithTimezone(date: string | Date, timezone: string | null = null): string {
        return this.formatShortDateYear(date, timezone) + ' ' +
            this.formatTime(date, timezone) + ' ' +
            getTimeZoneString(new Date(), timezone ?? 'UTC', this.$i18n.locale);
    }

    formatShortTime(time: string | Date, timezone: string | null = null): string {
        return formatShortTimeForLocale(this.$i18n.locale, time, timezone);
    }

    formatTimeFromTime(timeFragment: string): string {
        return this.formatTime('2000-01-01T' + timeFragment);
    }

    /**
     * Could be localized in the future for locales with different conventions
     * Can be called with either User entity or StaffLink.values
     *
     * @param staff
     */
    formatStaffName(staff: UserStub): string {
        return staff.first_name + ' ' + staff.last_name;
    }

    ordinalize(num: number): string {
        return String(num) + MiscUtils.ordinalSuffix(num);
    }

    // Functions to be used from utilities (requiring the locale to be included)

    formatTimeWithLocale(time: string | Date, timezone: string | null = null, locale: string): string {
        return formatTimeForLocale(locale, time, timezone);
    }

    formatDateWithoutLeadingZero(date: string | Date, timezone: string | null = null, locale: string): string {
        const dateString = formatDateForLocale(locale, date, timezone);
        let month = dateString.substring(0, 2);
        let day = dateString.substring(3, 5);
        const year = dateString.substring(6);
        if (month.substring(0, 1) === '0') {
            month = month.substring(1);
        }
        if (day.substring(0, 1) === '0') {
            day = day.substring(1);
        }
        return month + '/' + day + '/' + year;
    }

    formatTimeWithTimezoneWithoutLeadingZero(date: string | Date, timezone: string | null = null, locale: string): string {
        const dateObj = typeof date === 'string' ? new Date(date) : date;
        let timeString = this.formatTimeWithLocale(dateObj, timezone, locale);
        if (timeString.substring(0, 1) === '0') {
            timeString = timeString.substring(1);
        }

        return timeString + ' ' + getTimeZoneString(dateObj, timezone ?? 'UTC', locale);
    }
}
