
































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Email, OutgoingEmail } from '@/communications/messages/models/email';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { MessageDirection } from '@/communications/messages/models/message';
import {
    getEmailHeaderAttachments,
    getEmailHeaderBCC,
    getEmailHeaderCC,
    getEmailHeaderFrom,
    getEmailHeaderGroupEmail,
    getEmailHeaderTo
} from '@/communications/messages/email-utils';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';

const authState = getModule(AuthStore, store);

@Component({})
export default class OutboundEmailHeaderInfo extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
    @Prop() email!: Email;

    get isOutgoing() {
        return this.email?.type === MessageDirection.OUTGOING;
    }

    get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    get emailHeaderTo() {
        return this.isOutgoing ? getEmailHeaderTo(this.email as OutgoingEmail) : '';
    }

    get emailHeaderFrom() {
        return this.isOutgoing ? getEmailHeaderFrom(this.email as OutgoingEmail) : '';
    }

    get emailHeaderGroupEmail() {
        return this.isOutgoing ? getEmailHeaderGroupEmail(this.email as OutgoingEmail) : '';
    }

    get emailHeaderCC() {
        return this.isOutgoing ? getEmailHeaderCC(this.email as OutgoingEmail) : '';
    }

    get emailHeaderBCC() {
        return this.isOutgoing ? getEmailHeaderBCC(this.email as OutgoingEmail) : '';
    }

    private formatEmailHeaderDate(dateTime: string | null | undefined): string {
        return dateTime ? `${this.formatDate(dateTime, this.timezone)} at ${this.formatTime(dateTime, this.timezone)}` : '';
    }

      get emailHeaderProcessed() {
        return this.isOutgoing ? this.formatEmailHeaderDate((this.email as OutgoingEmail).events.processed?.date_time) : '';
    }

    get emailHeaderDelivered() {
        return this.isOutgoing ? this.formatEmailHeaderDate((this.email as OutgoingEmail).events.delivered?.date_time) : '';
    }

    get emailHeaderOpened() {
        const openEvents = (this.email as OutgoingEmail).events.open;
        return this.isOutgoing && openEvents.length > 0
            ? this.formatEmailHeaderDate(openEvents[openEvents.length - 1].date_time)
            : '';
    }

    get emailHeaderClicked() {
        const clickEvents = (this.email as OutgoingEmail).events.click;
        return this.isOutgoing && clickEvents.length > 0
            ? this.formatEmailHeaderDate(clickEvents[clickEvents.length - 1].date_time)
            : '';
    }

    get emailHeaderAttachments() {
        return this.isOutgoing ? getEmailHeaderAttachments(this.email as OutgoingEmail) : [];
    }
}
