












































































































































































































































































































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { Center, CenterHours, CenterDayType } from '@/organizations/locations/models/center';
import { EditMode } from '@/core/edit-modes';
import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { LoadingStore } from '@/store/loading-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { getDefault } from '@/crm-types/types-utils';
import { StaffStore } from '@/staff/store/staff-store';
import { User } from '@/staff/models/user';
import { CenterDto, CenterDtoInterface } from '@/organizations/locations/models/center-dto';
import { CenterMapper } from '@/organizations/locations/mappers/center-mapper';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import ManageHours from '@/organizations/locations/components/ManageHours.vue';
import { fixUrl } from '@/core/url-utils';
import { RateFile } from '@/enrollment-center/models/rate-file';
import { RateFileRepository } from '@/enrollment-center/repositories/rate-file-repository';
import { openFileBase64 } from '@/core/file-utils';
import UploadRateFile from '@/enrollment-center/components/UploadRateFile.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { isEqual } from 'lodash';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { centerHoursWeekdays } from '@/organizations/locations/models/center';
import { OrgsStore } from '@/store/orgs-store';
import { OrgLevelsStore } from '@/organizations/levels/store/org-levels-store';
import { OrgHierarchy } from '@/models/organization/org';
import { OrgsUtil } from '@/organizations/orgs-util';

const centersState = getModule(CentersStore);
const loadingState = getModule(LoadingStore);
const typesState = getModule(CrmTypesStore);
const staffState = getModule(StaffStore);
const orgsState = getModule(OrgsStore);
const orgsLevelsState = getModule(OrgLevelsStore);
const mapper = new CenterMapper();
const rateFileRepo = new RateFileRepository();
const centersRepo = new CentersRepository();
const orgsUtils = new OrgsUtil();

@Component({
    components: { UploadRateFile, ManageHours, CenterAscendingStaffList, CrmTypeSelectList }
})
export default class LocationInfoTab extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) location!: Center;
    @PropSync('tabMode') localMode!: EditMode;

    private loadingKey = 'locationInfoTab';
    private fullLocation: null | Center = null;
    private director: null | User = null;
    private asst: null | User = null;
    private centerDto: CenterDtoInterface = new CenterDto();
    private statuses: Array<CrmTypeOption> = [];
    private rateFile: RateFile | null = null;
    private editRateFile: RateFile | null = null;
    private showUploadRateFile = false;
    private uploadEvent = EventTypes.UPLOADED;
    private hoursWeekdays = centerHoursWeekdays;
    private centerStatusList = CrmTypeList.LOCATION_STATUS;
    private orgHierarchies: Array<OrgHierarchy> = []

    get isEdit() {
        return this.localMode !== EditMode.SAVED;
    }

    get defaultStatus() {
        return this.statuses && getDefault(this.statuses) ? getDefault(this.statuses).value : '';
    }

    get fixedUrl() {
        if (this.fullLocation && this.fullLocation.url) {
            return fixUrl(this.fullLocation?.url);
        }
        return '';
    }

    @Watch('location', { immediate: true, deep: true })
    async locationChanged() {
        loadingState.loadingIncrement(this.loadingKey);

        this.director = null;
        this.asst = null;
        this.fullLocation = await centersRepo.getOne(this.location.id);
        if (this.fullLocation.staff && this.fullLocation.staff.director) {
            this.director = await staffState.getById((this.fullLocation.staff.director.id));
        }
        if (this.fullLocation.staff && this.fullLocation.staff.assistant_director) {
            this.asst = await staffState.getById((this.fullLocation.staff.assistant_director.id));
        }

        this.centerDto = mapper.toUpdateDto(this.fullLocation);
        this.rateFile = await rateFileRepo.getRateFile(this.location.id);
        this.editRateFile = this.rateFile ? { ...this.rateFile } : null;
        this.orgHierarchies = this.retrieveOrgHierarchies();

        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('localMode', { immediate: true })
    async modeChanged() {
        if (this.localMode === EditMode.SAVED) {
            await this.locationChanged();
        }
        if (this.localMode === EditMode.SAVING) {
            // clearable sets value to undefined, which axios then ignores
            if (!this.centerDto.staff.assistant_director) {
                this.centerDto.staff.assistant_director = null;
            }

            this.centerDto.open_saturday = Boolean(this.centerDto.hours.sat_open);
            this.centerDto.open_sunday = Boolean(this.centerDto.hours.sun_open);

            await centersState.updateCenter({ centerId: this.location.id, dto: this.centerDto });

            if (this.rateFile && !this.editRateFile) {
                await rateFileRepo.deleteRateFile(this.location.id);
            }
            if (this.editRateFile && !isEqual(this.rateFile, this.editRateFile)) {
                await rateFileRepo.uploadRateFile(this.location.id, this.editRateFile);
            }

            this.localMode = EditMode.SAVED;
        }
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        const typesPromise = typesState.initList(CrmTypeList.LOCATION_STATUS);
        const orgsPromise = orgsState.init();
        const orgsLevelsPromise = orgsLevelsState.init();

        const promises = [
            typesPromise,
            orgsPromise,
            orgsLevelsPromise
        ];
        await Promise.all(promises);
        this.statuses = typesState.listOptions(CrmTypeList.LOCATION_STATUS);

        loadingState.loadingDecrement(this.loadingKey);
        // let all centers load in background
        await centersState.init();
    }

    openRateFile() {
        if (this.rateFile) {
            openFileBase64(this.rateFile.file, this.rateFile.filename, 'application/pdf', true);
        }
    }

    openEditRateFile() {
        if (this.editRateFile) {
            openFileBase64(this.editRateFile.file, this.editRateFile.filename, 'application/pdf', true);
        }
    }

    openRateFileUpload() {
        this.showUploadRateFile = true;
    }

    deleteRateFile() {
        this.editRateFile = null;
    }

    hoursDisplay(day: CenterDayType): string {
        if (!this.fullLocation) {
            return '';
        }
        const openKey = day + '_open' as keyof CenterHours;
        const closeKey = day + '_close' as keyof CenterHours;
        return this.fullLocation.hours[openKey] ? this.formatTimeFromTime(this.fullLocation.hours[openKey]!) + ' to ' + this.formatTimeFromTime(this.fullLocation.hours[closeKey]!) : 'closed';
    }

    rateFileUploaded(newFile: RateFile) {
        this.editRateFile = newFile;
        this.showUploadRateFile = false;
    }

    retrieveOrgHierarchies() {
        if (!this.location) {
            return [];
        }
        return orgsUtils.findOrgHierarchyForACenter(this.location, orgsState.stored, orgsLevelsState.stored);
    }
}
