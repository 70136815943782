














































































































































































































import {
    PendingFamilyRecording,
    Recording,
    RecordingActions
} from '@/communications/recordings/models/recording-models';
import { FamilyHubMixin } from '@/families/family-hub-mixin';
import type { Family } from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    ActionItem,
    ActionItemGroupType,
    ActionItemType
} from '@/dashboards/models/action-items-models';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { ActionItemUtils } from '@/dashboards/action-item-utils';
import { AvatarValues } from '@/core/avatar-utils';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { AppStateStore } from '@/store/app-state-store';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import LocationDashboard from '@/dashboards/views/LocationDashboard.vue';
import RecordingAudioModal from '@/communications/recordings/components/RecordingAudioModal.vue';
import { AddMeetingEventPayload, EditMeetingEventPayload } from '@/tasks/models/tour-calendar-models';
import TourCalendarModal from '@/tasks/components/TourCalendarModal.vue';
import SendMessageModal from '@/communications/messages/components/SendMessageModal.vue';
import ManageTaskModal from '@/tasks/components/ManageTaskModal.vue';
import { Task, TaskGroups } from '@/tasks/models/task-models';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import { RecordingsRepository } from '@/communications/recordings/repositories/recordings-repository';
import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';
import ViewMessageModal from '@/dashboards/components/LocationDashboardTabs/ViewMessageModal.vue';
import { PstToursAvailabilityTimeRange } from '@/pst-tours/models/pst-tours-availability';

const authState = getModule(AuthStore, store);
const actionItemUtils = new ActionItemUtils();
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
const tasksRepo = new TasksRepository();
const recordingsRepo = new RecordingsRepository();
const recordingsStore = getModule(RecordingsStore);

interface DisplayItem {
    item_type: ActionItemType;
    item_id: number;
    guardian: string;
    type_icon: string;
    type: string;
    description: string | null;
    assignee: string;
    avatar: AvatarValues | null;
    time: string;
    task_group_id: number | null;
    action: Array<string>;
}

type GroupTypeOption = ActionItemGroupType | 'all';

interface GroupType {
    label: string;
    type: GroupTypeOption;
}

@Component({
    components: {
        RecordingAudioModal,
        ViewMessageModal,
        AddTaskModal,
        TourCalendarModal,
        SendMessageModal,
        ManageTaskModal
    }
})
export default class FamilyActivityTable extends Mixins(LocaleMixin, CrmBreakpointsMixin, FamilyHubMixin) {
    @Prop({ required: true }) items!: Array<ActionItem>;
    @Prop({ required: true }) family!: Family;
    @Prop() itemsCount!: number;
    @Prop() customNoResults!: string | null;
    @Prop() noDataText!: string | null;
    @Prop({ default: false }) future!: boolean;

    private loadingKey = LocationDashboard.name;
    private displayItems: Array<DisplayItem> = [];
    private selectedRecordings: Array<PendingFamilyRecording | Recording> = [];
    private isMeeting = false;
    private showAddTask = false;
    private isSendMessage = false;
    private showCalendar = false;
    private startDateTime: string | null = null;
    private duration = 0;
    private selectedMeeting: Task | null = null;
    private isMeetingSelected = false;
    private closeEvent = EventTypes.CLOSE;
    private sentEvent = EventTypes.MESSAGE_SENT;
    private updatedEvent = EventTypes.UPDATED;
    private openEditTaskModalEvent = EventTypes.MEETING_EDIT;
    private openTaskModalEvent = EventTypes.MEETING_ADD;
    private addTaskModal = false;
    private addAnotherTaskEvent = EventTypes.TASK_ADD_ANOTHER;
    private createdEvent = EventTypes.CREATED;
    private managedTask: Task | null = null;
    private showMessageModal = false;
    private messageFamilyId = 0;
    private messageIsFb = false;
    private emailId = 0;
    private showRecordingModal = false;
    private today = new Date();
    private pstToursAvailabilityTimeRanges: Array<PstToursAvailabilityTimeRange> = [];

    private get headers(): Array<DataTableHeader> {
        return [
            {
                text: 'Type',
                value: 'type',
                width: this.typeWidth
            },
            {
                text: 'Assignee',
                value: 'assignee',
                width: this.assigneeWidth
            },
            {
                text: 'Time',
                value: 'time',
                class: 'time',
                width: this.timeWidth
            },
            {
                text: '',
                value: 'action',
                width: this.actionWidth,
                class: 'action',
                sortable: false
            }
        ];
    }

    get actionWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '13ch';
            case 'md':
                return '14ch';
            case 'lg':
                return '16ch';
            case 'xl':
                return '18ch';
        }
    }

    get assigneeWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return this.isMini ? '20ch' : '12ch';
            case 'md':
                return this.isMini ? '28ch' : '20ch';
            case 'lg':
                return this.isMini ? '32ch' : '24ch';
            case 'xl':
                return '36ch';
        }
    }

    get avatarSize() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '30';
            case 'md':
                return '32';
            case 'lg':
                return '34';
            case 'xl':
                return '36';
        }
    }

    get isMini() {
        return appState.isMini;
    }

    get timeWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '21ch';
            case 'md':
                return '22ch';
            case 'lg':
                return '24ch';
            case 'xl':
                return '27ch';
        }
    }

    get timezone() {
        return authState.userTimeZone;
    }

    get typeWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return this.isMini ? '22ch' : '18ch';
            case 'md':
                return this.isMini ? '28ch' : '24ch';
            case 'lg':
                return this.isMini ? '34ch' : '30ch';
            case 'xl':
                return '44ch';
        }
    }

    async getIcon(item: ActionItem) {
        return await actionItemUtils.getIcon(item);
    }

    @Watch('items', { immediate: true })
    async itemsChanged() {
        await this.updateDisplayItems();
    }

    /**
     * Handle trying to add a meeting from the calendar action.
     *
     * @param payload
     */
    private handleAddMeeting(payload: AddMeetingEventPayload) {
        this.startDateTime = payload.startDateTime;
        this.duration = payload.duration;
        this.showAddTask = true;
        this.pstToursAvailabilityTimeRanges = payload.pstToursAvailabilityTimeRanges;
    }

    /**
     * Handle trying to edit a meeting from the calendar action.
     *
     * @param payload
     */
    private handleEditMeeting(payload: EditMeetingEventPayload) {
        this.selectedMeeting = payload.selectedTask;
        this.isMeetingSelected = true;
    }

    loadData() {
        this.$emit(EventTypes.UPDATED);
    }

    async updateDisplayItems() {
        this.displayItems = [];
        for (const item of this.items) {
            const icon = await actionItemUtils.getIcon(item);
            this.displayItems.push({
                item_id: item.item_id,
                item_type: item.item_type,
                guardian: item.guardian_first_name + ' ' + item.guardian_last_name,
                description: actionItemUtils.getDescription(item),
                assignee: item.assignee_first_name + ' ' + item.assignee_last_name,
                avatar: actionItemUtils.getAvatar(item),
                time: item.date_time,
                type: item.display_type,
                type_icon: icon,
                action: this.getActionItems(item),
                task_group_id: item.task_group_id
            });
        }
    }

    /**
     * Get the list of actions items to show when clicking on the action button.
     *
     * @param item
     */
    getActionItems(item: ActionItem): Array<string> {
        switch (item.item_type) {
            case ActionItemType.CALL:
                // only other type that actually returns anything here, to be implemented in 12537
                return [RecordingActions.PLAY, RecordingActions.MARK_AS_LISTENED];
            default:
                // all other types are handled by direct action, in handleActionButtonClick()
                return [];
        }
    }

    mounted() {
        this.today.setHours(0, 0, 0);
    }

    getClasses(row: DisplayItem): string {
        if (this.future) {
            return '';
        }
        const classes = [];
        const dueDate = new Date(row.time);
        if (dueDate < this.today) {
            classes.push('task-past-due');
        }
        if (this.formatDate(dueDate) === this.formatDate(this.today)) {
            classes.push('task-due-today');
        }
        return classes.join(' ');
    }

    private handleAction(item: DisplayItem, actionString: string) {
        switch (item.item_type) {
            case ActionItemType.CALL:
                this.handleRecordingActionClick(item, actionString as RecordingActions);
                break;
            default:
                break;
        }
    }

    /**
     * This is for when there are no menu items for the item action options.
     */
    private async handleActionButtonClick(item: DisplayItem) {
        switch (item.item_type) {
            case ActionItemType.TASK:
                loadingState.loadingIncrement(this.loadingKey);
                this.isMeeting = [TaskGroups.MEETINGS, TaskGroups.TOURS].includes(item.task_group_id as number);
                this.managedTask = await tasksRepo.getOne(item.item_id);
                loadingState.loadingDecrement(this.loadingKey);
                break;
            case ActionItemType.EMAIL:
                // open email modal
                this.messageIsFb = false;
                this.messageFamilyId = 0;
                this.emailId = item.item_id;
                this.showMessageModal = true;
                break;
            case ActionItemType.TEXT:
                this.messageIsFb = false;
                this.messageFamilyId = item.item_id;
                this.emailId = 0;
                this.showMessageModal = true;
                break;
            case ActionItemType.FACEBOOK:
                this.messageIsFb = true;
                this.messageFamilyId = item.item_id;
                this.emailId = 0;
                this.showMessageModal = true;
                break;
        }
    }

    private async handleRecordingActionClick(item: DisplayItem, action: RecordingActions) {
        loadingState.loadingIncrement(this.loadingKey);
        const recording = await recordingsRepo.getOne(item.item_id);
        switch (action) {
            case RecordingActions.PLAY:
                this.selectedRecordings = [recording];
                this.showRecordingModal = true;
                break;
            case RecordingActions.MARK_AS_LISTENED:
                await recordingsRepo.markAsRead(recording, true);
                await this.loadData();
                recordingsStore.decrementInboxCount();
                break;
            case RecordingActions.VIEW_FAMILY:
                if (recording.family) {
                    await this.$router.push({
                        name: 'family-hub',
                        params: { id: recording.family.id.toString() }
                    });
                }
                break;
        }
        loadingState.loadingDecrement(this.loadingKey);

    }
}
