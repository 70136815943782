





























































































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import GoToLegacy from '@/dashboards/views/GoToLegacy.vue';
import DashboardSettingsModal from '@/dashboards/components/DashboardSettingsModal.vue';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import { LoadingStore } from '@/store/loading-store';
import TourCalendar from '@/components/tasks/TourCalendar.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { AppStateStore } from '@/store/app-state-store';
import StatusFilterFamilyLinks from '@/dashboards/components/StatusFilterFamilyLinks.vue';
import LocationDashboardRecentlyViewed
    from '@/dashboards/components/LocationDashboardTabs/LocationDashboardRecentlyViewed.vue';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import ChildStartsTab from '@/dashboards/components/LocationDashboardTabs/ChildStartsTab.vue';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import LocationDashboardToDoWrapper
    from '@/dashboards/components/LocationDashboardTabs/LocationDashboardToDoWrapper.vue';
import { LocationDashboardStore } from '@/dashboards/store/location-dashboard-store';
import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
import { TabTitles } from '@/router/route-constants';
import store from '@/store';
import LocationAvailabilityWrapper from '@/dashboards/components/LocationDashboardTabs/LocationAvailabilityWrapper.vue';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';
import { NavigationGuardNext, Route } from 'vue-router';
import DashboardCheckMixin from '@/dashboards/components/custom/DashboardCheckMixin.vue';
import { actionDashboardType, enrollmentDashboardType, CustomDashboard, DashElementIdentifier } from '@/dashboards/models/custom-dashboards';

const featureStore = getModule(FeaturesStore);
const loadingStore = getModule(LoadingStore);
const appState = getModule(AppStateStore);
const familyRepository = new FamiliesRepository();
const staffUtils = new StaffUtils();
const settingsState = getModule(InterfaceSettingsStore);
const dashboardState = getModule(LocationDashboardStore);
const layoutTabsStore = getModule(LayoutTabsStore, store);

@Component({
    components: {
        BasePageTitle,
        LocationDashboardToDoWrapper,
        LocationDashboardRecentlyViewed,
        ChildStartsTab,
        TourCalendar,
        GoToLegacy,
        StatusFilterFamilyLinks,
        LocationAvailabilityWrapper
    }
})
export default class LocationDashboard extends Mixins(LocaleMixin, PageTitleMixin, DashboardCheckMixin) {

    async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        appState.setIsDashboard(false);
        next();
    }

    @Prop({ default: false }) readonly custDashMode!: boolean;
    @Prop() readonly dashConfig!: CustomDashboard | null | undefined;
    @Prop({ default: false }) readonly etDashMode!: boolean;

    // for dashboard check mixin
    dashboardType = (this.etDashMode ? enrollmentDashboardType : actionDashboardType);

    private currentTab = 0;
    private tabsKey = 0;
    private dashboardTitleAll = 'Action Dashboard';
    private dashboardTitleUser = 'My Action Dashboard';
    private dashboardEnrollTitleAll = 'Enrollment Dashboard';
    private dashboardEnrollTitleUser = 'My Enrollment Dashboard';
    private isSettingsVisible = false;
    private loadingKey = LocationDashboard.name;
    private settingsComponent = DashboardSettingsModal;
    private startsCount = 0;
    private toDoCount = 0;
    private toursCount = 0;
    private toursCountUpdate = EventTypes.TOURS_COUNT;
    private countEvent = EventTypes.COUNT;
    private toDoSearch = '';
    private actualToDoSearch = '';
    private toursSearch = '';
    private actualToursSearch = '';
    private viewedFamiliesCount = 0;
    private toDoTabId = DashElementIdentifier.TODO_TAB;
    private calendarTabId = DashElementIdentifier.CALENDAR;
    private childStartsTabId = DashElementIdentifier.CHILD_STARTS;
    private availTabId = DashElementIdentifier.AVAILABILITY;
    private recentlyViewedTabId = DashElementIdentifier.RECENTLY_VIEWED;
    private showPstAvailableHours = false;
    private toggleTarget = false;

    // Getters / Setters
    get isCenter(): boolean {
        return !!appState.storedCurrentCenter;
    }

    get isClassroomsFeatureEnabled(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    get isCrmPlus(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isMineOnly(): boolean {
        return dashboardState.onlyMine;
    }

    set isMineOnly(flag: boolean) {
        dashboardState.setOnlyMine(flag);
    }

    get locationDashboardContainerClass(): string {
        const style = 'd-flex text-left responsive-container-px-extra px-0';
        return this.currentTab !== 1 ? style : `${style} fc-holder-small`;
    }

    private get org() {
        return appState.storedCurrentOrg;
    }

    get showChildStarts() {
        return Boolean(settingsState.settingByName(SettingNames.SHOW_CHILD_STARTS) ?? true);
    }

    get includeMeetings() {
        return Boolean(settingsState.settingByName(SettingNames.INCLUDE_MEETINGS) ?? false);
    }

    // Watchers
    @Watch('org', {
        immediate: true,
        deep: true
    })
    async orgUpdated() {
        this.viewedFamiliesCount = await familyRepository.getLastViewFamiliesCountByStaff(this.org?.id);
    }

    @Watch('isMineOnly')
    updateTitle() {
        if (!this.custDashMode) {
            if (!this.etDashMode) {
                this.setPageTitle(this.isMineOnly ? this.dashboardTitleUser : this.dashboardTitleAll);
            } else {
                this.setPageTitle(this.isMineOnly ? this.dashboardEnrollTitleUser : this.dashboardEnrollTitleAll);
            }
        }
    }

    @Watch('toggleTarget')
    private updateTargetMode() {
        appState.updateFamilyHubTargetMode(this.toggleTarget);
    }

    @Watch('showPstAvailableHours')
    updateShowPstCheckBox() {
        appState.setShowPstAvailableHours(this.showPstAvailableHours);
    }

    // Lifecycle
    async created() {
        appState.setIsDashboard(true);
        this.toggleTarget = appState.familyHubTargetMode;
        this.isSettingsVisible = await staffUtils.getUserPermission(PermissionName.SettingsAllGears);
        loadingStore.loadingIncrement(this.loadingKey);
        await settingsState.init();
        this.showPstAvailableHours = appState.displayPstAvailableHours;
        loadingStore.loadingDecrement(this.loadingKey);
    }

    // Update the tab display.
    async mounted() {
        // Update the tab title, since 'Add Tab' can't set it correctly.
        if (this.custDashMode) {
            return;
        }
        const outerTab = layoutTabsStore.tabs[layoutTabsStore.currentTabIndex] as LayoutTab;
        outerTab.props.routeName = this.$route.name ?? TabTitles.DASHBOARD;
        outerTab.props.routeParams = this.$route.params;
        outerTab.props.tabTitle = (this.etDashMode ? TabTitles.ENROLLMENT_DASHBOARD : TabTitles.LOCATION_DASHBOARD);
        layoutTabsStore.updateTab(outerTab);
        this.updateTitle();
    }

    // Methods
    async loadData() {
        loadingStore.loadingIncrement(this.loadingKey);
        await settingsState.init();
        loadingStore.loadingDecrement(this.loadingKey);
    }

    clearToDoSearch() {
        this.toDoSearch = '';
        this.actualToDoSearch = '';
    }

    doToDoSearch() {
        if (this.toDoSearch.length > 2) {
            this.actualToDoSearch = this.toDoSearch;
        } else {
            this.actualToDoSearch = '';
        }
    }

    clearToursSearch() {
        this.toursSearch = '';
        this.actualToursSearch = '';
    }

    doToursSearch() {
        if (this.toursSearch.length > 2) {
            this.actualToursSearch = this.toursSearch;
        } else {
            this.actualToursSearch = '';
        }
    }

    showTab(id: DashElementIdentifier) {
        return !this.custDashMode || this.dashConfig?.elements.map(elem => elem.identifier).includes(id);
    }

    updateStartsCount(count: number) {
        this.startsCount = count;
        this.tabsKey++;
    }

    updateToDoCount(count: number) {
        this.toDoCount = count;
        this.tabsKey++;
    }

    updateToursCount(count: number) {
        this.toursCount = count;
        this.tabsKey++;
    }
}
