









































    import { LocaleMixin } from '@/locales/locale-mixin';
    import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
    import { TaskReminderUnits } from '@/tasks/models/task-models';
    import pluralize from 'pluralize';
    import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
    import { getModule } from 'vuex-module-decorators';
    import { FeaturesStore } from '@/features/features-store';

    const featureState = getModule(FeaturesStore);
@Component
    export default class SendReminderInputs extends Mixins(LocaleMixin, BasicValidationMixin) {
    @PropSync('amountInput', { type: String }) amountSynced!: string;
    @PropSync('unitInput', { type: String }) unitSynced!: string;

    @Prop({ default: false }) readonly sendReminder!: boolean;
    @Prop({ default: false }) readonly disabled!: boolean;

    private isValid = true;
    private capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    get isLineLeaderEnroll(): boolean {
            return featureState.isLineLeaderEnroll;
    }

    private reminderUnitOptions = [
        {
            value: TaskReminderUnits.MINUTES,
            label: this.capitalizeFirstLetter(pluralize(TaskReminderUnits.MINUTES))
        },
        {
            value: TaskReminderUnits.HOURS,
            label: this.capitalizeFirstLetter(pluralize(TaskReminderUnits.HOURS))
        },
        {
            value: TaskReminderUnits.DAYS,
            label: this.capitalizeFirstLetter(pluralize(TaskReminderUnits.DAYS))
        }
        ];

    @Watch('amountSynced')
    updateAmountInput() {
        this.$emit('input-amount');
    }

    @Watch('unitSynced')
    updateUnitInput() {
        this.$emit('input-unit');
    }
    }

