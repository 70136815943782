import { getModule } from 'vuex-module-decorators';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { TaskGroupsRepository } from '@/tasks/repositories/task-groups-repository';
import { TaskGroupsStore } from '@/tasks/stores/task-groups-store';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { TaskGroup, TaskGroups, TaskGroupTableItem } from '@/tasks/models/task-models';
import { TaskTypesRepository } from '@/tasks/repositories/task-types-repository';
import { TaskResultsRepository } from '@/tasks/repositories/task-results-repository';
import { TaskTypesStore } from '@/tasks/stores/task-types-store';

export interface GroupedType {
    typeId: number | null;
    typeValue: string;
    order: number;
    isEditable: boolean;
    groupId: number | null;
    groupValue: string | null;
}

export interface ResultGroupedType extends GroupedType {
    isLog: boolean;
    isSystemEvent: boolean;
}

export default class TaskGroupsService {
    private taskTypes: Array<GroupedType> = [];
    private resultTypes: Array<ResultGroupedType> = [];
    private taskGroupsList: Array<TaskGroup> = [];
    private typesStore = getModule(CrmTypesStore);
    private taskTypesStore = getModule(TaskTypesStore);
    private taskGroupsRepo = new TaskGroupsRepository();
    private taskGroupsStore = getModule(TaskGroupsStore);
    private taskTypesRepository = new TaskTypesRepository();
    private resultTypesRepository = new TaskResultsRepository();
    private taskTypesToDelete: Array<number> = [];
    private resultTypesToDelete: Array<number> = [];
    private taskGroupsToDelete: Array<number> = [];
    private newTaskGroupItemsCount = 0;
    private taskGroupTableItems: Array<TaskGroupTableItem> = [];

    static get onlyLogId() {
        return -1;
    }

    get resultGroupedTypes(): Array<ResultGroupedType> {
        return this.resultTypes;
    }

    get taskGroupedTypes(): Array<GroupedType> {
        return this.taskTypes;
    }

    get taskGroups(): Array<TaskGroup> {
        return this.taskGroupsList;
    }

    get taskGroupTableDisplayItems(): Array<TaskGroupTableItem> {
        return this.taskGroupTableItems;
    }

    // Because constructors can't be async...
    async init() {
        this.resultTypes = [];
        this.taskTypes = [];
        this.newTaskGroupItemsCount = 0;
        const initResults = this.typesStore.initList(CrmTypeList.TASK_RESULTS);
        const initTasks = this.typesStore.initList(CrmTypeList.TASKS);
        const taskGroupAwait = this.taskGroupsRepo.getAll();
        await initResults;
        await initTasks;
        this.taskGroupsList = (await taskGroupAwait).entities;
        this.taskGroupTableItems = this.taskGroupsList.map((group: TaskGroup) => ({
            id: group.id,
            name: group.name,
            is_editable: group.is_editable,
            is_editing: false
        }));
        const resultTypes = this.typesStore.listVisibleOptions(CrmTypeList.TASK_RESULTS);
        const taskTypes = this.typesStore.listVisibleOptions(CrmTypeList.TASKS);

        // For tracking if a result type has already appeared
        // That's allowed in the legacy interface, but not in CRM+, so we'll only show the first group
        const resultIdsFound: Array<number> = [];
        // Join groups with types
        for (const group of this.taskGroups) {
            const resultIds = group.preferred_result_types.map(item => item.id);
            const taskIds = group.task_types.map(item => item.id);

            for (const item of resultTypes) {
                if (resultIds.includes(item.id) && !resultIdsFound.includes(item.id)) {
                    this.resultTypes.push({
                        typeId: item.id,
                        typeValue: item.value,
                        order: item.order,
                        isEditable: item.is_editable ?? true,
                        groupId: item.is_task_result ? group.id : TaskGroupsService.onlyLogId,
                        groupValue: group.name,
                        isSystemEvent: item.is_system_event ?? false,
                        isLog: item.is_log ?? false
                    });
                    resultIdsFound.push(item.id);
                }
            }

            for (const item of taskTypes) {
                if (taskIds.includes(item.id)) {
                    this.taskTypes.push({
                        typeId: item.id,
                        typeValue: item.value,
                        order: item.order,
                        isEditable: item.is_editable ?? true,
                        groupId: group.id,
                        groupValue: group.name
                    });
                }
            }
        }

        this.resultTypes.sort((a, b) => a.order - b.order);
        this.taskTypes.sort((a, b) => a.order - b.order);
    }

    addResultType(): ResultGroupedType {
        const newResultType: ResultGroupedType = {
            typeId: null,
            typeValue: '',
            order: this.getMaxOrder(this.resultTypes) + 1,
            isEditable: true,
            groupId: TaskGroups.OTHER,
            groupValue: 'Other',
            isLog: false,
            isSystemEvent: false
        };
        this.resultTypes.push(newResultType);
        return newResultType;
    }

    addTaskType(): GroupedType {
        const newTaskType = {
            typeId: null,
            typeValue: '',
            order: this.getMaxOrder(this.taskTypes) + 1,
            isEditable: true,
            groupId: TaskGroups.OTHER,
            groupValue: 'Other'
        };
        this.taskTypes.push(newTaskType);
        return newTaskType;
    }

    addTaskGroup(): TaskGroupTableItem {
        const newTaskGroup = {
            id: -Math.abs(++this.newTaskGroupItemsCount),
            name: 'Group Name',
            is_editable: true,
            is_editing: true
        } as TaskGroupTableItem;
        this.taskGroupTableItems.push(newTaskGroup);
        return newTaskGroup;
    }

    /**
     * Get the grouped tasks types.
     * Can exclude types from the tour and meeting groups if desired.
     *
     * @param includeToursAndMeetings
     */
    getTaskGroupedTypes(includeToursAndMeetings = true) {
        if (includeToursAndMeetings) {
            return this.taskGroupedTypes;
        }
        return this.taskGroupedTypes.filter(groupedType => !groupedType.groupId || ![TaskGroups.TOURS, TaskGroups.MEETINGS].includes(groupedType.groupId));
    }

    async save() {

        // Add any new types first because we need them to have ids
        for (const taskType of this.taskTypes) {
            if (taskType.typeId === null && taskType.typeValue.trim().length) {
                const type = await this.taskTypesRepository.post({
                    value: taskType.typeValue,
                    is_default: false,
                    order: taskType.order
                });
                taskType.typeId = type.id;
            }
        }
        for (const resultType of this.resultTypes) {
            if (resultType.typeId === null && resultType.typeValue.trim().length) {
                const isTaskResult = !!resultType.groupId && resultType.groupId > 0;
                if (!isTaskResult) {
                    // should be taken care of by front-end, but just in case...
                    resultType.isLog = true;
                }
                const type = await this.resultTypesRepository.post({
                    value: resultType.typeValue,
                    is_default: false,
                    order: resultType.order,
                    is_log: resultType.isLog,
                    is_task_result: isTaskResult
                });
                resultType.typeId = type.id;
            }
        }

        // Update items that changed
        for (const resultType of this.typesStore.listOptions(CrmTypeList.TASK_RESULTS)) {
            for (const localResultType of this.resultGroupedTypes) {
                const isTaskResult = !!localResultType.groupId && localResultType.groupId > 0;
                if (!isTaskResult && !localResultType.isSystemEvent) {
                    localResultType.isLog = true;
                }
                if (localResultType.typeId === resultType.id &&
                    localResultType.typeValue.trim().length &&
                    (localResultType.order !== resultType.order ||
                        localResultType.typeValue !== resultType.value ||
                            isTaskResult !== resultType.is_task_result ||
                            localResultType.isLog !== resultType.is_log
                    )
                ) {
                    await this.resultTypesRepository.putOne(resultType.id, {
                        order: localResultType.order,
                        value: localResultType.typeValue,
                        is_default: resultType.is_default,
                        is_log: localResultType.isLog,
                        is_task_result: isTaskResult
                    } as CrmTypeOption);
                }
            }
        }

        for (const taskType of this.typesStore.listOptions(CrmTypeList.TASKS)) {
            for (const localTaskType of this.taskGroupedTypes) {
                if (localTaskType.typeId === taskType.id &&
                    localTaskType.typeValue.trim().length &&
                    (localTaskType.order !== taskType.order ||
                        localTaskType.typeValue !== taskType.value
                    )
                ) {
                    await this.taskTypesRepository.putOne(taskType.id, {
                        order: localTaskType.order,
                        value: localTaskType.typeValue,
                        is_default: taskType.is_default
                    } as CrmTypeOption);
                }
            }
        }

        // Delete types that need deleting
        for (const id of this.taskTypesToDelete) {
            await this.taskTypesRepository.deleteBasic(id);
        }
        for (const id of this.resultTypesToDelete) {
            await this.resultTypesRepository.deleteBasic(id);
        }

        for (const taskGroupItem of this.taskGroupTableDisplayItems) {
            if (taskGroupItem.id < 0 && taskGroupItem.name.trim().length) {
                await this.taskGroupsRepo.post({
                    name: taskGroupItem.name
                });
            }
            if (taskGroupItem.id > 0 && taskGroupItem.is_editable && taskGroupItem.is_editing && taskGroupItem.name.trim().length) {
                const originalGroup = this.taskGroups.find(group => group.id === taskGroupItem.id);
                if (originalGroup && originalGroup.name !== taskGroupItem.name) {
                    await this.taskGroupsRepo.putOne(taskGroupItem.id,
                        { name: taskGroupItem.name }
                    );
                }
            }
        }
        // Update all of the groups with all the new ids
        for (const group of this.taskGroups) {
            const groupData = {
                task_types: this.getIdsForGroup(this.taskTypes, group.id),
                preferred_result_types: this.getIdsForGroup(this.resultTypes, group.id)
            };
            const taskGroup = await this.taskGroupsRepo.putOne(group.id, groupData);
            // Update task group store with new values
            this.taskGroupsStore.replaceEntity(taskGroup);
        }

        for (const id of this.taskGroupsToDelete) {
            await this.taskGroupsRepo.deleteBasic(id);
        }

        // Update type stores with new values
        const taskTypes = (await this.taskTypesRepository.getAll()).entities;
        const resultTypes = (await this.resultTypesRepository.getAll()).entities;
        this.typesStore.storeList({ list: CrmTypeList.TASKS, options: taskTypes });
        this.typesStore.storeList({ list: CrmTypeList.TASK_RESULTS, options: resultTypes });
        this.taskTypesStore.storeList(taskTypes);

        // Clear out our data
        this.taskTypesToDelete = [];
        this.resultTypesToDelete = [];
    }

    removeResult(item: GroupedType): Array<GroupedType> {
        this.removeItem(item, this.resultTypes, this.resultTypesToDelete);
        return this.resultGroupedTypes;
    }

    removeTaskGroup(item: TaskGroupTableItem): Array<TaskGroupTableItem> {
        this.removeTaskGroupItem(item, this.taskGroupTableItems, this.taskGroupsToDelete);
        return this.taskGroupTableDisplayItems;
    }

    removeTask(item: GroupedType): Array<GroupedType> {
        this.removeItem(item, this.taskTypes, this.taskTypesToDelete);
        return this.taskGroupedTypes;
    }

    updateResultsOrder(oldOrder: number, newOrder: number): Array<GroupedType> {
        this.updateOrder(this.resultGroupedTypes, oldOrder, newOrder);
        return this.resultGroupedTypes;
    }

    updateTasksOrder(oldOrder: number, newOrder: number): Array<GroupedType> {
        this.updateOrder(this.taskGroupedTypes, oldOrder, newOrder);
        return this.taskGroupedTypes;
    }

    private getIdsForGroup(list: Array<GroupedType>, groupId: number): Array<number> {
        const ids = [];
        for (const item of list) {
            if (item.groupId === groupId && item.typeId) {
                ids.push(item.typeId);
            } else if (groupId === TaskGroups.OTHER && item.groupId && item.groupId < 0 && item.typeId) {
                ids.push(item.typeId);
            }
        }
        return ids;
    }

    private getMaxOrder(list: Array<GroupedType>): number {
        return Math.max(...list.map(item => item.order));
    }

    private removeItem(itemToRemove: GroupedType, list: Array<GroupedType>, deleteList: Array<number>) {
        for (const item of list) {
            if (itemToRemove.typeId !== null && item.typeId === itemToRemove.typeId) {
                deleteList.push(itemToRemove.typeId);
                list.splice(list.indexOf(item), 1);
            }
        }
    }

    private removeTaskGroupItem(itemToRemove: TaskGroupTableItem, list: Array<TaskGroupTableItem>, deleteList: Array<number>) {
        // If the item is new, just remove it from the list
        if (itemToRemove.id < 0) {
            list.splice(list.indexOf(itemToRemove), 1);
            --this.newTaskGroupItemsCount;
        } else {
            for (const item of list) {
                if (itemToRemove.id > 0 && item.id === itemToRemove.id) {
                    deleteList.push(item.id);
                    list.splice(list.indexOf(item), 1);
                }
            }
        }
    }

    private updateOrder(list: Array<GroupedType>, oldOrder: number, newOrder: number) {
        for (const item of list) {
            if (item.order === oldOrder) {
                const movedItem = list.splice(list.indexOf(item), 1)[0];
                movedItem.order = newOrder;
                list.splice(newOrder, 0, movedItem);
            }
        }
        // We only need to update this order
        // The API handles re-ordering all the other items
    }
}
