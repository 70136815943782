


































































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import {
    ActionItem,
    ActionItemGroupType, ActionItemResultCounts,
    ActionItemsSortKey,
    ActionItemsSortParameter, BulkActionsTypes,
    PendAndCommType
} from '@/dashboards/models/action-items-models';
import ActionItemsRepository, { ActionItemsParams } from '@/dashboards/repositories/action-items-repository';
import { LoadingStore } from '@/store/loading-store';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import LocationDashboardToDo from '@/dashboards/components/LocationDashboardTabs/LocationDashboardToDo.vue';
import { LocationDashboardStore } from '@/dashboards/store/location-dashboard-store';
import { DataTableOptions } from '@/models/datatables';
import { getPagination, getSortingFromOptions } from '@/core/datatables-utils';
import { SortConstants } from '@/constants/sort-constants';
import { EventTypes } from '@/constants/event-type-constants';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import LocationDashboardBulkActionsModal from '@/dashboards/components/LocationDashboardTabs/LocationDashboardBulkActionsModal.vue';
import LocationDashboardBulkActionsButton from '@/dashboards/components/LocationDashboardTabs/LocationDashboardBulkActionsButton.vue';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';

const loadingState = getModule(LoadingStore);
const appState = getModule(AppStateStore);
const actionItemsRepo = new ActionItemsRepository();
const dashboardState = getModule(LocationDashboardStore);
const enrollmentSettingsStore = getModule(EnrollmentCenterSettingsStore);

@Component({
    components: { LocationDashboardToDo, LocationDashboardBulkActionsModal, LocationDashboardBulkActionsButton }
})
export default class PendingAndCommToDos extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
    @Prop() isCenter!: boolean;
    @Prop() isMineOnly!: boolean;
    @Prop({ default: false }) readonly etDashMode!: boolean;
    @PropSync('expanded') expandedSync!: boolean;

    private loadingKey = 'pendingAndCommToDos';
    private loaded = false;
    private items: Array<ActionItem> = [];
    private pendingType: PendAndCommType = ActionItemGroupType.PENDING;
    private commType: PendAndCommType = ActionItemGroupType.COMM;
    private updatedEvent = EventTypes.UPDATED;

    private search = '';
    private actualSearch = '';

    private defaultSorting: ActionItemsSortParameter = {
        sort_keys: [ActionItemsSortKey.DATETIME],
        sort_dir: [SortConstants.ASCENDING]
    };

    private actionType: BulkActionsTypes | null = null;
    private resultsCount: ActionItemResultCounts = {
        pending: 0,
        comm: 0,
        items: 0,
        total: 0
    }

    private showBulkActionsModal = false;

    get title() {
        let teamName = '';
        if (this.etDashMode) {
            teamName = enrollmentSettingsStore.storedSettings ? enrollmentSettingsStore.storedSettings.name : '';
        }
        return `${teamName} Pending Families & Messages`;
    }

    get currentOrg() {
        return appState.storedCurrentOrg?.id ?? 0;
    }

    get commCount() {
        return this.resultsCount.comm ?? 0;
    }

    /**
     * This will disable pending card constraint if we can't see at least two lines of items
     */
    get isTallEnough() {
        return this.$vuetify.breakpoint.height > 780;
    }

    get itemsCount() {
        return Number(this.resultsCount.items ?? 0);
    }

    get pendingCount() {
        return this.resultsCount.pending ?? 0;
    }

    get tableOptions() {
        return dashboardState.pendAndCommPagination;
    }

    set tableOptions(options: DataTableOptions) {
        dashboardState.setPendAndCommPagination(options);
    }

    get totalCount() {
        return this.pendingCount + this.commCount;
    }

    get type() {
        return dashboardState.pendAndCommType;
    }

    set type(type: PendAndCommType) {
        dashboardState.setPendAndCommType(type);
    }

    get isSearching() {
        return !!this.actualSearch;
    }

    get isCommTablePrompt() {
        return !this.commCount && !this.pendingCount && !this.isSearching;
    };

    get tablePrompt() {
        if (!this.loaded) {
            return 'Loading table...';
        } else {
            return 'No pending families and no pending messages--no unfinished work here!';
        }
    }

    get itemCountMoreThanTen() {
        return this.itemsCount > 10;
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.etDashMode) {
            await enrollmentSettingsStore.init();
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('actualSearch')
    async searchChanged() {
        await this.loadData();
    }

    @Watch('currentOrg', { immediate: true })
    async orgChanged() {
        await this.loadData();
    }

    @Watch('isMineOnly')
    async mineOnlyChanged() {
        await this.loadData();
    }

    @Watch('type')
    async typeChanged() {
        await this.loadData();
    }

    @Watch('tableOptions', { deep: true })
    async paginationChanged() {
        await this.loadData();
    }

    clearSearch() {
        this.search = '';
        this.actualSearch = '';
    }

    doSearch() {
        if (this.search.length >= 2) {
            this.actualSearch = this.search;
        } else {
            this.actualSearch = '';
        }
    }

    async loadData() {
        if (!this.currentOrg) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        const itemsParams: ActionItemsParams = {
            non_tasks_dash_mode: true,
            org_id: this.currentOrg,
            only_mine: this.isMineOnly,
            search: this.actualSearch
        };
        if (this.type) {
            itemsParams.type = this.type;
        }
        if (this.etDashMode) {
            itemsParams.only_enrollment_team = true;
        }
        const pagination = getPagination(this.tableOptions);
        const sort = getSortingFromOptions(this.tableOptions, this.defaultSorting);
        const resultsPromise = actionItemsRepo.getActionItems(itemsParams, pagination, sort);
        const results = await resultsPromise;
        this.items = results.items;
        // this is what's used for actual table pagination
        this.resultsCount.items = results.counts.items;
        this.resultsCount.pending = results.counts.pending;
        this.resultsCount.comm = results.counts.comm;
        // make sure we're not stranded
        const maxPage = Math.ceil(this.itemsCount / this.tableOptions.itemsPerPage);
        if (this.tableOptions.page > maxPage) {
            this.tableOptions.page = Math.max(1, maxPage);
        }
        this.emitTotal();
        this.loaded = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    emitTotal() {
        // can't directly use itemsCount because of searching
        if (this.type === ActionItemGroupType.PENDING) {
            this.$emit(EventTypes.COUNT, this.pendingCount);
            return;
        }
        if (this.type === ActionItemGroupType.COMM) {
            this.$emit(EventTypes.COUNT, this.commCount);
            return;
        }
        this.$emit(EventTypes.COUNT, this.totalCount);
    }

    handleAction(type: BulkActionsTypes) {
        this.actionType = type;
        this.showBulkActionsModal = true;
    }
}
