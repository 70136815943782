import { AbstractRepository } from '@/repositories/abstract-repository';
import { PstToursAvailability } from '@/pst-tours/models/pst-tours-availability';

export class PstToursAvailabilityRepository extends AbstractRepository<PstToursAvailability> {
    readonly endpoint = '';
    public async retrieveAll(centerId: number, startDateTime: string, endDateTime: string, no7DaysLimit = true): Promise<Array<PstToursAvailability>> {
        const response = await this.silentClient.get<Array<PstToursAvailability>>(`centers/${centerId}/tour-availability?start_datetime=${startDateTime}&end_datetime=${endDateTime}&no_7_days_limit=${no7DaysLimit}`);
        return response.data;
    }
}
