import { FieldLink } from '@/automation/landing-pages/models/landing-page-fields';
import { WorkflowActiveStatus } from '@/automation/workflows/constants/workflow-constants';
import { CrmEntity, HateoasLink, NamedLink } from '@/models/base';
import { StaffLink } from '@/staff/models/user';
import { StatusLink } from '@/families/models/status';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { SortParameter } from '@/models/sort-parameter';

export interface Workflow extends CrmEntity {
    actions: Array<WorkflowAction>;
    conditions: Array<WorkflowCondition>;
    description: string;
    family_added_type: HateoasLink | null;
    is_enabled: boolean;
    is_for_drip_campaigns: boolean;
    enabled_center_ids: Array<number>;
    last_edited_by_staff: StaffLink | null;
    last_edited_datetime: string | null;
    name: string;
    stop_triggers: Array<WorkflowStopTrigger>;
    active_status?: WorkflowActiveStatus; // Only set on the workflows table
}

export interface WorkflowCentersUpdate {
    enabled_center_ids: Array<number>;
}

export interface WorkflowAction {
    type: WorkflowActionType;
    settings: WorkflowActionSettings;
}

export type WorkflowActionSettings = WorkflowActionChangeStatusSettings | WorkflowActionEmailSettings | WorkflowActionEventSettings |
    WorkflowActionNotificationSettings | WorkflowActionTaskSettings | WorkflowActionTextSettings | WorkflowActionDripCampaignSettings |
    WorkflowActionFacebookMessageSettings;

export interface WorkflowActionChangeStatusSettings {
    status: StatusLink;
}

export interface WorkflowActionSendTimeSettings {
    days_before?: number;
    days_before_field?: HateoasLink | null;
    send_delay_amount: number;
    send_delay_units: HateoasLink;
}

export interface WorkflowActionEmailSettings extends WorkflowActionSendTimeSettings {
    template: HateoasLink | null;
    template_method: HateoasLink;
    template_group: HateoasLink | null;
    reply_to_position: HateoasLink;
    from_email_type: HateoasLink;
    from_name_type: HateoasLink;
}

export interface WorkflowActionEventSettings {
    type: HateoasLink;
    description: string;
}

export interface WorkflowActionNotificationSettings {
    template: HateoasLink;
    send_to_type: HateoasLink;
    reply_to_position: HateoasLink;
    send_delay_amount: number;
    send_delay_units: HateoasLink;
    staff_group: HateoasLink | null;
}

export interface WorkflowActionTaskSettings {
    type: HateoasLink;
    description: string;
    due_amount: number;
    due_units: HateoasLink;
    assign_to_position: HateoasLink;
    notification_type: HateoasLink | null;
    staff_reminder_email_amount: number;
    staff_reminder_email_units: HateoasLink;
    staff_reminder_text_amount: number;
    staff_reminder_text_units: HateoasLink;
    family_reminder_email_amount: number;
    family_reminder_email_units: HateoasLink;
    family_reminder_text_amount: number;
    family_reminder_text_units: HateoasLink;
}

export interface WorkflowActionTextSettings extends WorkflowActionSendTimeSettings {
    template: HateoasLink | null;
    template_method: HateoasLink;
    template_group: HateoasLink | null;
}

export interface WorkflowActionFacebookMessageSettings {
    template: HateoasLink | null;
    template_method: HateoasLink;
    template_group: HateoasLink | null;
}

export interface WorkflowActionDripCampaignSettings {
    drip_campaign: NamedLink;
}

export interface WorkflowActionType {
    id: number;
    name: string;
}

export interface WorkflowCondition {
    entity: HateoasLink;
    field: FieldLink;
    target_entity: HateoasLink | null;
    value_one: HateoasLink | string | number | null;
    value_two: HateoasLink | number | null;
    is_not_equal: boolean;
}

export interface WorkflowStopTrigger {
    entity: WorkflowStopTriggerEntity;
    field: WorkflowStopTriggerField | null;
    value: WorkflowStopTriggerValue;
}

export interface WorkflowStopTriggerEntity {
    id: number;
    value: string;
}

export interface WorkflowStopTriggerField extends CrmEntity {
    entity: HateoasLink;
    name: string;
}

export interface WorkflowStopTriggerValue extends CrmEntity {
    entity: HateoasLink;
    is_default: boolean;
    order: number;
    value: string;
}

export interface WorkflowCreateDto extends AbstractCreateDto {
    name: string;
    description: string;
    is_enabled: boolean;
    family_added_type: number | null;
    is_for_drip_campaigns: boolean;
    conditions: Array<WorkflowConditionDto>;
    actions: Array<WorkflowActionDto>;
    stop_triggers: Array<WorkflowStopTriggerDto>;
    enabled_center_ids: null | Array<number>;
}

export interface WorkflowConditionDto {
    entity: number;
    target_entity: number | null;
    field: number;
    value_one: number;
    value_two: number | null;
    is_not_equal: boolean;
}

export interface WorkflowActionDto {
    type: number;
    settings: {} | WorkflowActionSettingsEmailDto | WorkflowActionSettingsTextDto | WorkflowActionSettingsChangeStatusDto |
        WorkflowActionSettingsTaskDto | WorkflowActionSettingsStaffNotificationDto | WorkflowActionSettingsEventDto |
        WorkflowActionSettingsFacebookMessageDto | WorkflowActionSettingsDripCampaignDto;
}

export interface WorkflowActionSettingsWithTemplateDto {
    template: number | null;
    template_method: number;
}

export interface WorkflowActionSettingsWithSendTimeDto {
    send_delay_amount: number;
    send_delay_units: number;
    days_before: number;
    days_before_field: number | null;
}

export interface WorkflowActionSettingsEmailDto extends WorkflowActionSettingsWithTemplateDto, WorkflowActionSettingsWithSendTimeDto {
    template_group: number | null;
    reply_to_position: number;
    from_email_type: number;
    from_name_type: number;
}

export interface WorkflowActionSettingsFacebookMessageDto extends WorkflowActionSettingsWithTemplateDto {
    template_group: number | null;
}

export interface WorkflowActionSettingsTextDto extends WorkflowActionSettingsWithTemplateDto, WorkflowActionSettingsWithSendTimeDto {
    template_group: number | null;
}

export interface WorkflowActionSettingsEventDto {
    type: number;
    description: string;
}

export interface WorkflowActionSettingsChangeStatusDto {
    status: number;
}

export interface WorkflowActionSettingsDripCampaignDto {
    drip_campaign: number;
}

export interface WorkflowActionSettingsTaskDto {
    type: number;
    description: string;
    due_amount: number;
    due_units: number;
    assign_to_position: number;
    notification_type: null | number;
    staff_reminder_email_amount: number;
    staff_reminder_email_units: number;
    staff_reminder_text_amount: number;
    staff_reminder_text_units: number;
    family_reminder_email_amount: number;
    family_reminder_email_units: number;
    family_reminder_text_amount: number;
    family_reminder_text_units: number;
}

export interface WorkflowActionSettingsStaffNotificationDto {
    template: number;
    send_to_type: number;
    reply_to_position: number;
    send_delay_amount: number;
    send_delay_units: number;
    staff_group: number | null;
}

export interface WorkflowStopTriggerDto {
    entity: number;
    field: number | null;
    value: number;
}

export interface WorkflowUpdateDto extends WorkflowCreateDto, AbstractUpdateDto {

}

export enum WorkflowSortKeys {
    NAME = 'name'
}

export interface WorkflowSortParameter extends SortParameter {
    sort_keys: Array<WorkflowSortKeys>;
}
