var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card-actions',[_c('v-col',{attrs:{"cols":"9"}},[(_vm.canAddAttachment)?_c('base-button-primary',{attrs:{"text":false,"data-cy":"upload-attachment-button"},on:{"click":function($event){_vm.manageOpen = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Upload File ")],1):_vm._e()],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[(_vm.allItemsCount >= 10)?_c('base-text-field',{directives:[{name:"debounce",rawName:"v-debounce:600ms",value:(_vm.doSearch),expression:"doSearch",arg:"600ms"}],attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search Attachments","clearable":"","data-cy":"search-attachment-field"},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1),_c('base-data-table',{staticClass:"pt-2",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"must-sort":"","sort-by":"filename","data-cy":"email-attachments-table"},scopedSlots:_vm._u([{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.filename))])]}},{key:"item.mime_type",fn:function(ref){
var item = ref.item;
return [(item.mime_type)?_c('span',[_vm._v(_vm._s(item.mime_type))]):_vm._e()]}},{key:"item.file_size",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Math.round(item.file_size / 1024))+" KB")])]}},{key:"item.created_datetime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_datetime)))])]}},{key:"item.created_by_staff",fn:function(ref){
var item = ref.item;
return [(item.created_by_staff)?_c('span',[_vm._v(_vm._s(item.created_by_staff.values.first_name)+" "+_vm._s(item.created_by_staff.values.last_name))]):_vm._e()]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.can_edit)?_c('base-button-secondary',{staticClass:"view-workflow",on:{"click":function($event){return _vm.editAttachment(item)}}},[_c('span',[_vm._v("Edit / View")])]):_c('span')],1)]}}])}),_c('manage-email-attachment-modal',{attrs:{"email-attachment":_vm.managedEmailAttachment},on:_vm._d({},[_vm.updated,_vm.loadAttachments,_vm.closed,_vm.managedClosed]),model:{value:(_vm.manageOpen),callback:function ($$v) {_vm.manageOpen=$$v},expression:"manageOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }