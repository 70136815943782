import { CrmApiElement, CrmEntity, HateoasLink } from '@/models/base';
import { GuardianLink } from '@/families/models/guardian';
import { EmailThread } from '@/communications/messages/models/email';
import { TextThread } from '@/communications/messages/models/text';
import { FacebookThread } from '@/communications/messages/models/facebookMessage';
import { StaffLink } from '@/staff/models/user';

export enum MessageDirection {
    OUTGOING = 'outgoing',
    INCOMING = 'incoming'
}

export enum OutgoingStatusString {
    SENT = 'Sent',
    PENDING = 'Pending',
    FAILED = 'Failed',
    CANCELLED = 'Canceled'
}

// Yes, enums auto-map from 0, but this is for "safety".
export enum OutgoingStatusNumber {
    SENT = 0,
    PENDING = 1,
    FAILED = 2,
    CANCELLED = 3
}

// Group communication history is returned with a type and not grouped like others.
export enum CommunicationType {
    EMAIL = 'email',
    TEXT = 'text',
    FACEBOOK = 'facebook'
}

export interface Message extends CrmEntity {
    type: MessageDirection;
    sent_date_time: string | null;
    send_to_guardian: GuardianLink | null;
}

export interface IncomingMessage {
    is_read: boolean;
    is_dismissed: boolean;
}

export type MessageThread = EmailThread | TextThread | FacebookThread;

export interface ThreadMeta {
    thread: MessageThread;
    is_read: boolean;
    is_dismissed: boolean;
    incoming_id: number;
    is_text: boolean;
    is_email: boolean;
    family_id: number;
    sent_date_time: string | null;
}

export const messageThreadLimit = 5;

export interface GroupMessageDto {
    template: number | null;
    content: string;
    excluded_families: Array<number>;
    included_families: Array<number>;
    org_id: number;
    send_date_time: string;
    notify_director?: boolean;
    communication_type?: number | null;
}

export interface GroupCommHistory extends CrmEntity {
    can_be_deleted: boolean;
    cc_secondary_guardian: boolean;
    content: string;
    date_sent: string;
    name: string;
    status: HateoasLink;
    subject: string | null; // Text and Facebook messages don't have subjects.
    template: HateoasLink | null;
    type: CommunicationType;
}

export interface GroupEmailStats {
    delivered: number;
    opened: number;
    unique_opened: number;
    clicked: number;
    failed: number;
    spam_reported: number;
    sent: number;
    date_sent: number;
    families: number;
    sent_by_user: StaffLink;
}

export interface GroupTextStats {
    delivered: number;
    failed: number;
    undelivered: number;
    sent: number;
    date_sent: number;
    families: number;
    sent_by_user: StaffLink;
}

export enum GroupCommDisplayStats {
    families,
    processed,
    sent,
    delivered,
    opened,
    unique_opened,
    clicked,
    undelivered,
    failed,
    spam_reported
}

export interface GroupHistoryRecipient extends CrmApiElement {
    email?: string;
    phone_number?: string;
    family_id: number;
    recipient_name: string;
    delivery_status: string;
}

export interface InboxCount {
    count: number;
}

export enum MessageDelayUnits {
    MINUTES = 'minute',
    HOURS = 'hour',
    DAYS = 'day'
}

export enum MessageLinkParams {
    EMAIL = 'email-id',
    FACEBOOK = 'facebook-id',
    OUTBOUND_EMAIL = 'outbound-email-id',
    TEXT = 'text-id'
}
