import { Workflow, WorkflowCentersUpdate, WorkflowSortParameter } from '@/automation/workflows/models/workflow-models';
import { AbstractRepository, ApiPagination, ApiParameters } from '@/repositories/abstract-repository';
import { CrmApiResponse } from '@/models/base';

export class WorkflowsRepository extends AbstractRepository<Workflow> {
    readonly endpoint = 'settings/automation/workflows';

    public async getWorkflows(
        pagination: ApiPagination | null = null,
        sortParam: WorkflowSortParameter | null = null,
        queryParams: ApiParameters | null = null
    ): Promise<CrmApiResponse<Workflow>> {
        const params = {};
        // Merge all the params together so we get types on the parameters first
        Object.assign(params, queryParams, sortParam);
        return await super.get(pagination, params as ApiParameters);
    }

    /**
     * Enable or disable a workflow.
     *
     * @param workflowId The id of the workflow
     * @param isEnabled  Whether or not it is enabled
     * @param orgId      The id of the organization to enable/disable the workflow for
     */
    public async toggleWorkflow(workflowId: number, isEnabled: boolean, orgId: number | undefined): Promise<Workflow> {
        let url = `${this.endpoint}/${workflowId}`;
        if (orgId) {
            url += `?org_id=${orgId}`;
        }
        const response = await this.client.patch<Workflow>(url, { is_enabled: isEnabled });
        return response.data;
    }

    public async checkLocation(workflowId: number, centerId: number): Promise<boolean> {
        const response = await this.client.get<Workflow>(`${this.endpoint}/${workflowId}`, {
            params: { center_id: centerId }
        });

        return response.data.is_enabled;
    }

    public async updateLocation(workflowId: number, centerId: number, isEnabled: boolean): Promise<void> {
        await this.client.put(`${this.endpoint}/${workflowId}` + '/centers/' + `${centerId}`, { is_enabled: isEnabled });
    }

    public async updateLocations(workflowId: number, update: WorkflowCentersUpdate): Promise<Workflow> {
        const response = await this.client.patch(`${this.endpoint}/${workflowId}`, update);
        return response.data;
    }

}
