





































































































































































import { CommunicationTypes } from '@/communications/communication-constants';
import SendMessageModal from '@/communications/messages/components/SendMessageModal.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { getFamilyCell, getFamilyEmail } from '@/families/families-utils';
import type { Family } from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import { Task } from '@/tasks/models/task-models';
import { AddMeetingEventPayload, EditMeetingEventPayload } from '@/tasks/models/tour-calendar-models';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import LogEventModal from '@/tasks/components/LogEventModal.vue';
import { PstToursAvailabilityTimeRange } from '@/pst-tours/models/pst-tours-availability';

@Component({
    components: {
        AddTaskModal,
        LogEventModal,
        ManageTaskModal: () => import('@/tasks/components/ManageTaskModal.vue'), // There are circular dependencies
        SendMessageModal,
        TourCalendarModal: () => import('@/tasks/components/TourCalendarModal.vue')
    }
})
export default class FamilyContactButtons extends Mixins(LocaleMixin) {
    @Prop() family!: Family;
    @Prop({ default: true }) showMeeting!: boolean;
    @Prop({ default: false }) showFamilyHub!: boolean;
    @Prop({ default: false }) showLog!: boolean;

    private closeEvent = EventTypes.CLOSE;
    private duration = 0;
    private isAddMeeting = false;
    private isMeetingSelected = false;
    private isSendMessage = false;
    private messageSentEvent = EventTypes.MESSAGE_SENT;
    private messageType = CommunicationTypes.TEXT;
    private openEditTaskModalEvent = EventTypes.MEETING_EDIT;
    private openTaskModalEvent = EventTypes.MEETING_ADD;
    private selectedMeeting: Task | null = null;
    private showCalendar = false;
    private showLogEvent = false;
    private startDateTime: string | null = null;
    private updatedEvent = EventTypes.UPDATED;
    private viewFamilyEvent = EventTypes.FAMILY_VIEW;
    private pstToursAvailabilityTimeRanges: Array<PstToursAvailabilityTimeRange> = [];

    private get isEmailDisabled() {
        return this.family.do_not_email || !getFamilyEmail(this.family);
    }

    private get isTextDisabled() {
        return this.family.do_not_text || !getFamilyCell(this.family);
    }

    /**
     * Handle trying to add a meeting from the calendar action.
     *
     * @param payload
     */
    private handleAddMeeting(payload: AddMeetingEventPayload) {
        this.startDateTime = payload.startDateTime;
        this.duration = payload.duration;
        this.isAddMeeting = true;
        this.pstToursAvailabilityTimeRanges = payload.pstToursAvailabilityTimeRanges;
    }

    /**
     * Handle trying to edit a meeting from the calendar action.
     *
     * @param payload
     */
    private handleEditMeeting(payload: EditMeetingEventPayload) {
        this.selectedMeeting = payload.selectedTask;
        this.isMeetingSelected = true;
    }

    private messageSent() {
        this.$emit(EventTypes.MESSAGE_SENT);
    }

    private sendEmail() {
        this.messageType = CommunicationTypes.EMAIL;
        this.isSendMessage = true;
    }

    private sendText() {
        this.messageType = CommunicationTypes.TEXT;
        this.isSendMessage = true;
    }

    private emitTaskAdded() {
        this.$emit('taskAddedEvent');
    }
}
