import { Task } from '@/tasks/models/task-models';
import { EventInput } from '@fullcalendar/vue';
import { addMinutes, formatDateWithTimezone, isoFormatLong2 } from '@/date-time/date-time-utils';
import { isTaskPastDue } from '@/tasks/task-utils';
import { IcsFile, IcsFileContent } from '@/calendar/ics-file-content-model';
import { openFile } from '@/core/file-utils';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { NylasCalendarEvent } from '@/integrations/models/nylas';
import { PstToursAvailabilityTimeRange } from '@/pst-tours/models/pst-tours-availability';

const authState = getModule(AuthStore, store);
export function eventStatus(entity: Task): string {
    if (entity.is_completed) {
        return '(Completed)';
    }
    if (isTaskPastDue(entity)) {
        return '(Past Due)';
    }
    return '';
}

export function pstToursAvailabilityEventsToCalendarEvents(events: Map<string, Array<PstToursAvailabilityTimeRange>>, timezone: string): Array<EventInput> {
    const calendarEvents: Array<EventInput> = [];

    events.forEach((timeRanges) => {
        timeRanges.forEach((range) => {
            calendarEvents.push({
                title: '',
                start: formatDateWithTimezone(range.start_datetime, timezone, isoFormatLong2),
                end: formatDateWithTimezone(range.end_datetime, timezone, isoFormatLong2),
                allDay: false,
                extendedProps: {
                    group: 'pst-tours-availability',
                    icon: ''
                }
            });
        });
    });

    return calendarEvents;
}

export function nylasEventsToCalendarEvents(events: Array<NylasCalendarEvent>, timezone: string): Array<EventInput> {
    return events.map(
        (event: NylasCalendarEvent) => {
            return {
                meeting: event,
                title: `${event.title}`,
                allDay: false,
                start: formatDateWithTimezone(event.start_date_time, timezone, isoFormatLong2),
                end: formatDateWithTimezone(event.end_date_time, timezone, isoFormatLong2),
                extendedProps: {
                    group: 'external',
                    icon: ''
                }
            };
        }
    );
}

export function toursToCalendarEvents(tours: Array<Task>, timezone: string): Array<EventInput> {
    return tours.filter((entity: Task) => {
        return !entity.is_cancelled;
    }).map((entity: Task) => {
        const ret: EventInput = {
            meeting: entity,
            title: `${entity.family?.values?.name} - ${entity.type?.values?.value}`,
            allDay: false,
            start: formatDateWithTimezone(entity.due_date_time, timezone, isoFormatLong2)
        };

        ret.extendedProps = {};
        ret.extendedProps.completed = entity.is_completed ? '<i class="fas fa-check-circle" />' : '';
        ret.extendedProps.group = entity.group.values.name;
        ret.extendedProps.pastDue = isTaskPastDue(entity);
        ret.extendedProps.pastDueMessage = eventStatus(entity);
        ret.extendedProps.icon = (entity.type?.values?.value === 'Tour' || entity.type?.values?.value === 'Virtual Tour') ? '<i class="fas fa-school" />' : '<i class="fas fa-handshake" />';

        if (entity.duration) {
            ret.end = formatDateWithTimezone(addMinutes(entity.due_date_time, entity.duration), timezone, isoFormatLong2);
        }

        return ret;
    });
}

export function pad(n: number) {
    return n < 10 ? `0${n}` : n;
}

// Date time string with no punctuation accepted in the .ics file format
export function generateTimeIcs(dateTime: Date): string {
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const date = dateTime.getDate();
    const hour = dateTime.getHours();
    const minute = dateTime.getMinutes();
    const second = dateTime.getSeconds();
    return `${year.toString()}${pad(month)}${pad(date)}T${pad(hour)}${pad(minute)}${pad(second)}`;
}

// Replicate generating unique_ids for .ics file in the legacy
export function generateUniqueIdIcs(dateTime: Date, prodId: string, id: number): string {
    const dateTimeString = generateTimeIcs(dateTime);
    const timezoneAbbreviation = dateTime.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ')[2];
    return `${id}-${dateTimeString}${timezoneAbbreviation}@${prodId}`;
}

export function generateIcsContent(tour: Task): IcsFileContent {
    const now = new Date();
    const domain = 'childcarecrm.com';
    const icsFileContent: IcsFileContent = {
        prod_id: domain,
        method: 'REQUEST',
        location: tour.center.values.name,
        start_date_time: generateTimeIcs(new Date(tour.due_date_time)),
        end_date_time: generateTimeIcs(new Date(addMinutes(tour.due_date_time, tour.duration ?? 0))),
        summary: `${tour.type.values.value} with ${tour.family.values.name}`,
        unique_id: generateUniqueIdIcs(new Date(tour.added_date_time), domain, tour.id),
        description: `Event: ${tour.type.values.value} \\nWith: ${tour.family.values.name} \\nNotes or Description: ${tour.description}`,
        timestamp: generateTimeIcs(now),
        organizer: 'mailto:donotreply@childcarecrm.com',
        attendee: `CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=NEEDS-ACTION;RSVP=FALSE;CN=${authState.userFullName};X-NUM-GUESTS=0:mailto:${authState.userEmailAddress}`
    };
    return icsFileContent;
}

export function generateIcsFile(icsFileContent: IcsFileContent): IcsFile {
    const icsFile: IcsFile = {
        file_contents: '',
        file_name: icsFileContent.timestamp,
        mime_type: 'text/calendar'
    };

    const content =
        'BEGIN:VCALENDAR\n' +
        'VERSION:2.0\n' +
        'PRODID:-//' + icsFileContent.prod_id + '//\n' +
        'CALSCALE:GREGORIAN\n' +
        'METHOD:' + icsFileContent.method + '\n' +
        'BEGIN:VEVENT\n' +
        'UID:' + icsFileContent.unique_id + '\n' +
        'DTSTAMP:' + icsFileContent.timestamp + '\n' +
        'DTSTART:' + icsFileContent.start_date_time + '\n' +
        'DTEND:' + icsFileContent.end_date_time + '\n' +
        'SUMMARY:' + icsFileContent.summary + '\n' +
        'DESCRIPTION:' + icsFileContent.description + '\n' +
        'LOCATION:' + icsFileContent.location + '\n' +
        'ORGANIZER:' + icsFileContent.organizer + '\n' +
        'ATTENDEE;' + icsFileContent.attendee + '\n' +
        'END:VEVENT\n' +
        'END:VCALENDAR';
    icsFile.file_contents = content;
    return icsFile;
}

export function downloadIcsFile(task: Task) {
    const icsFileContent = generateIcsContent(task);
    const icsFile = generateIcsFile(icsFileContent);
    openFile(icsFile.file_contents, icsFile.file_name, icsFile.mime_type);
}
