export enum IntegrationPartners {
    CARE_COM = 'CareCom',
    DCW = 'Daycare Works',
    EZ_CARE_ONLINE = 'EZ Care Online',
    INCONTACT = 'InContact',
    JACKRABBIT = 'Jackrabbit',
    JULIE_BARKTUS = 'Julie Barktus',
    JULIE_WASSOM = 'Julie Wassom',
    KANGAROO_TIME = 'Kangarootime',
    KRIS_MURRAY = 'Kris Murray',
    LMN_TREE = 'LMN-TREE',
    MORE_THAN_JUST_GREAT_DANCING = 'More Than Just Great Dancing',
    ONCARE_OLD = 'Oncare',
    ONCARE = '1Core',
    PROCARE = 'Procare',
    QIKKIDS = 'QikKids',
    SMART_CARE = 'Smart Care',
    ONECORE = '1Core',
    CARE_FOR_KIDS = 'Care for Kids',
    WINNIE = 'Winnie',
    KINSIDE = 'Kinside',
    KIDSOFT = 'KidSoft',
    MANAGE = 'Manage',
    XAP = 'Xap'
}

export enum IntegrationTypes {
    OTHER = 1,
    CMS = 2,
    LEAD_REFERRAL = 5,
    CALL_CENTER = 7
}

export enum IntegrationPartnerIds {
    CARE_COM = 17,
    KANGAROO_TIME = 12,
    MANAGE = 32,
    PROCARE = 1,
    QIKKIDS = 10
}
