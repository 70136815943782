


































































import ManageTaskModal from '@/tasks/components/ManageTaskModal.vue';
import { Task } from '@/tasks/models/task-models';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import FullCalendar, { EventApi, CalendarApi, EventInput, EventClickArg, DateSelectArg } from '@fullcalendar/vue';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { LoadingStore } from '@/store/loading-store';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import timeGridPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';
import { pstToursAvailabilityEventsToCalendarEvents, toursToCalendarEvents } from '@/calendar/calendar-utils';
import { AppStateStore } from '@/store/app-state-store';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';

import {
    addDays,
    diffMinutes,
    formatDateWithTimezone, isDateInPast,
    isoFormatLong,
    yearMonthFormat
} from '@/date-time/date-time-utils';
import interactionPlugin from '@fullcalendar/interaction';
import { Center } from '@/organizations/locations/models/center';
import { EventTypes } from '@/constants/event-type-constants';
import { getContinuousAvailabilityRangesByDate } from '@/pst-tours/pst-tours-utils';
import { PstToursAvailabilityRepository } from '@/pst-tours/repositories/pst-tours-availability-repository';
import { PstToursAvailabilityTimeRange } from '@/pst-tours/models/pst-tours-availability';

const authState = getModule(AuthStore, store);
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
const tasksRepo = new TasksRepository();
const pstToursAvailabilityRepo = new PstToursAvailabilityRepository();

@Component({
    components: {
        ManageTaskModal,
        FullCalendar,
        AddTaskModal
    }
})
export default class DashboardTourCalendar extends Mixins(LocaleMixin) {
    @Prop() overrideCenter!: Center | undefined;
    private monthMode = false;
    private monthEvents: Array<string> = [];
    private monthCalendarDay = '';
    private isMeetingSelected = false;
    private selectedTask: Task | null = null;
    private isAddTask = false;
    private startDateTime: string | null = null;
    private duration = 0;
    private closeEvent = EventTypes.CLOSE;
    private updateEvent = EventTypes.UPDATED;
    private showPstAvailableHours = false;
    private availabilityByDateMap: Map<string, Array<PstToursAvailabilityTimeRange>> = new Map();

    $refs!: {
        calendar: any;
    };

    get center() {
        return this.overrideCenter ?? appState.storedCurrentCenter;
    }

    get orgId() {
        return this.overrideCenter ? this.overrideCenter.organization_id : (appState.storedCurrentOrg ? appState.storedCurrentOrg.id : 0);
    }

    get scrollValue(): number {
        const date = new Date(formatDateWithTimezone(new Date(), authState.userTimeZone, 'yyyy-MM-dd HH:mm:ss'));
        return date.setHours(date.getHours() - 1);
    }

    get timezone() {
        return authState.userTimeZone;
    }

    get pstToursAvailabilityTimeRanges(): Array<PstToursAvailabilityTimeRange> {
        if (this.showPstAvailableHours && this.startDateTime) {
            const dateString = this.startDateTime.substring(0, 10);
            return this.availabilityByDateMap.get(dateString) || [];
        }
        return [];
    }

    get calendarOptions() {
        return {
            plugins: [
                timeGridPlugin,
                interactionPlugin // needed for dateClick
            ],
            customButtons: {
                monthCustom: {
                    text: 'Month',
                    click: this.handleMonthClick
                }
            },
            headerToolbar: {
                left: 'today',
                center: 'prev,title,next',
                right: 'monthCustom'
            },
            titleFormat: this.formatHeader,
            scrollTime: new Date(this.scrollValue).toLocaleTimeString('en-GB'),
            initialDate: formatDateWithTimezone(new Date(), authState.userTimeZone, 'yyyy-MM-dd'),
            firstDay: this.firstDay,
            initialView: 'timeGridDay',
            height: '100%',
            allDaySlot: false,
            dayMaxEvents: true,
            weekends: true,
            selectable: true,
            selectMirror: false,
            eventDidMount: this.eventRender,
            select: this.handleDateSelect,
            eventClassNames: this.classNamesCallback,
            eventClick: this.handleEventClick,
            nowIndicator: true,
            timeZone: authState.userTimeZone,
            locales: allLocales,
            locale: this.fullCalendarLocale,
            events: this.retrieveCalendarEvents,
            loading: function(isLoading: boolean) {
                if (isLoading) {
                    loadingState.loadingIncrement('dashboardTourCalendar');
                } else {
                    loadingState.loadingDecrement('dashboardTourCalendar');
                }
            }
        };
    };

    created() {
        this.showPstAvailableHours = appState.displayPstAvailableHours;
    }

    async retrieveTours(arg: { start: Date; end: Date }) {
        if (!this.orgId) {
            return [];
        }
        const tours = await tasksRepo.getToursInRange(addDays(arg.start, -2), addDays(arg.end, 2), null, {
            org_id: String(this.orgId)
        });
        return toursToCalendarEvents(tours.entities, authState.userTimeZone);
    };

    async retrievePstTourAvailability(arg: { start: Date; end: Date }): Promise<EventInput[] | never[]> {
        const startDate = new Date(arg.start);
        startDate.setHours(0, 0, 0, 0);
        let startDateTime = addDays(startDate, -2);

        const endDate = new Date(arg.end);
        endDate.setHours(23, 59, 59, 999);
        let endDateTime = addDays(endDate, 2);

        if (!this.center || !this.center.id || isDateInPast(endDateTime)) {
            return [];
        }

        startDateTime = startDateTime.replace('T', ' ').substring(0, 19);
        endDateTime = endDateTime.replace('T', ' ').substring(0, 19);
        const availabilities = await pstToursAvailabilityRepo.retrieveAll(
            this.center.id,
            startDateTime,
            endDateTime
        );
        this.availabilityByDateMap = getContinuousAvailabilityRangesByDate(availabilities);
        return pstToursAvailabilityEventsToCalendarEvents(this.availabilityByDateMap, this.timezone);
    }

    async retrieveCalendarEvents(arg: { start: Date; end: Date }): Promise<Array<EventInput>> {
        const events = await this.retrieveTours(arg);
        const pstToursAvailabilityEvents = this.showPstAvailableHours ? await this.retrievePstTourAvailability(arg) : [];
        return [...events, ...pstToursAvailabilityEvents];
    }

    formatHeader(header: { date: { marker: Date } }) {
        return this.formatDate(header.date.marker, 'UTC');
    }

    classNamesCallback(info: { event: EventApi }) {
        if (info.event.extendedProps) {
            if (info.event.extendedProps.pastDue) {
                return ['tour-calendar-' + info.event.extendedProps.group.toLowerCase() + '-past-due'];
            }
            return ['tour-calendar-' + info.event.extendedProps.group.toLowerCase()];
        }
        return [];
    }

    eventRender(info: { event: EventApi; el: HTMLElement }) {
        info.el.setAttribute('title', info.event.title);
    }

    handleMonthClick() {
        const calendarDay = (this.$refs.calendar.getApi() as CalendarApi).getDate();
        this.monthCalendarDay = formatDateWithTimezone(calendarDay, 'UTC', yearMonthFormat);
        this.monthMode = true;
        this.retrieveMonthEvents();
    }

    gotoDay(day: string) {
        this.monthMode = false;
        (this.$refs.calendar.getApi() as CalendarApi).gotoDate(day);
        (this.$refs.calendar.getApi() as CalendarApi).refetchEvents();
    }

    @Watch('orgId')
    private async updateOrg() {
        if (this.orgId) {
            (this.$refs.calendar.getApi() as CalendarApi).refetchEvents();
        }
    }

    // section for handling vuetify month calendar

    @Watch('monthCalendarDay')
    vuetifyCalendarChanged() {
        this.retrieveMonthEvents();
    }

    @Watch('showPstAvailableHours')
    async updateShowPstCheckBox() {
        appState.setShowPstAvailableHours(this.showPstAvailableHours);
        (this.$refs.calendar.getApi() as CalendarApi).refetchEvents();
    }

    updateCalendar() {
        (this.$refs.calendar.getApi() as CalendarApi).refetchEvents();
    }

    async retrieveMonthEvents() {
        const start = new Date(`${this.monthCalendarDay}-01`);
        const end = new Date(start);
        end.setDate(start.getDate() + 32);
        loadingState.loadingIncrement('dashboardTourCalendar');
        const fcEvents = await this.retrieveTours({
            start: start,
            end: end
        });
        this.monthEvents = fcEvents.map((fcEvent: EventInput) => {
            return (fcEvent.start as string).substr(0, 10);
        });
        loadingState.loadingDecrement('dashboardTourCalendar');
    }

    handleEventClick(clickInfo: EventClickArg) {
        this.selectedTask = clickInfo.event.extendedProps.meeting as Task;
        this.isMeetingSelected = true;
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        this.startDateTime = formatDateWithTimezone(selectInfo.start, 'UTC', isoFormatLong);
        this.duration = diffMinutes(selectInfo.end, selectInfo.start);
        this.isAddTask = true;
    }

}
